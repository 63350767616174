import React from 'react';
import './AgePopup.scss';

import vuseLogo from "./../../images/vuseLogo.svg";

const AgePopup = (props) => {

  return (
    <div className="AgePopup">
      <div className="age-popup-container">
        <a href="/">
          <img alt="Vuse logo" src={vuseLogo} />
        </a>
        <p className="age-popup-title">TO USE THIS WEBSITE YOU MUST BE AGED 18 YEARS OR OVER</p>
        <p className="age-popup-subtitle">Please verify your age before entering the site*</p>
        <div className="button-container">
          <button className="white-button" onClick={() => exitWebsite()}><b>I AM UNDER 18</b></button>
          <button className="yellow-button" onClick={() => props.accessWebsite()}>I AM 18 OR OVER</button>
        </div>
        <p className="notice-text">
          *18+ only. This product contains nicotine and is addictive.<br />
          For adult nicotine or tobacco consumers only.
        </p>
      </div>
    </div>
  )

  function exitWebsite() {
    window.location.replace("http://www.google.com");
  }
}

export default AgePopup;
