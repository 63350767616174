import React from 'react';

import './home2.sass';

import Header from '../../components//Header/header.js';

import Footer from '../../components/Footer/Footer';
import LegalFooter from '../../components/LegalFooter/LegalFooter';

import FeatureBloc from '../../components/FeatureBloc/FeatureBloc';
import AndroidBlock from '../../components/AndroidBlock/AndroidBlock';
import IOSBlock from '../../components/IOSBlock/IOSBlock';

import findMyVapeIcon from './../../images/desktop/find_my-vape.svg';
import rechargeReminderIcon from './../../images/desktop/recharge-reminder.svg';
import podTrackerIcon from './../../images/desktop/tracker.svg';
import favouritesIcon from './../../images/desktop/faves.svg';
import lockDeviceIcon from './../../images/desktop/device-lock.svg';
import cloudControlIcon from './../../images/desktop/cloud-control.svg';
import usageTrackingIcon from './../../images/desktop/usage-tracker.svg';
import easyReorderIcon from './../../images/desktop/easy-reorder.svg';

import PhoneCarousel from '../../components/PhoneCarousel/PhoneCarousel';

const Home2 = () => {
    return <>
        <div className="header">
            <div className="header-content">
                <Header />
            </div>

            <div className="header-mobile-bg">
                <FeatureBloc
                    title={'FIND MY VAPE'}
                    description={"Can’t find your device? Don't worry, we'll show you it's last synced location."}
                    icon={findMyVapeIcon}
                />
                <FeatureBloc
                    title={'CLOUD CONTROL'}
                    description={'Use the app to control your vapour cloud size..'}
                    icon={cloudControlIcon}
                />
                <FeatureBloc
                    title={'USAGE TRACKING'}
                    description={'Track your device usage with puff and pod count.'}
                    icon={usageTrackingIcon}
                />
                <FeatureBloc
                    title={'EASY REORDER'}
                    description={'All your favourites will be waiting for you in your basket, ready for you to reorder.'}
                    icon={easyReorderIcon}
                />
            </div>

        </div>

        <div className="block-3">
            <PhoneCarousel />
            <p className="small-bold-text">SCROLL DOWN TO EXPLORE<br />KEY FEATURES</p>
        </div>

        <div className="block-4">
            <div className="mobile-content">
                <FeatureBloc
                    title={'RECHARGE REMINDER'}
                    description={'Make sure your device is always charged by setting alerts for your battery levels.'}
                    icon={rechargeReminderIcon}
                />
                <FeatureBloc
                    title={'POD TRACKER'}
                    description={"We can notify you when you are running out of pods by setting a threshold."}
                    icon={podTrackerIcon}
                />
                <FeatureBloc
                    title={'FAVOURITES'}
                    description={"Speed up your order, once they're in your favourites, your order is just one click away."}
                    icon={favouritesIcon}
                />
                <FeatureBloc
                    title={'LOCK DEVICE'}
                    description={'Lock your device so no one else but you has access.'}
                    icon={lockDeviceIcon}
                />
            </div>
            <div className="desktop-content">
                <div className="install-app-side-by-side">
                    <AndroidBlock />
                    <IOSBlock />
                </div>
            </div>
        </div>

        <div className="block-5">
            <AndroidBlock />
        </div>

        <div className="block-6">
            <IOSBlock />
        </div>
        <div className="footers">
            <Footer />
            <LegalFooter></LegalFooter>
        </div>
    </>
}

export default Home2