import React from 'react';
import './UsageTracker.scss';

import './../../content-page.scss';

import vuseLogo from "./../../../images/vuseLogo.svg";
import LegalFooter from '../../../components/LegalFooter/LegalFooter';

const UsageTracker = () => (
  <>
    <div id="content-page">
      <a href="/">
        <img src={vuseLogo} alt=""></img>
      </a>
      <h1>Usage Tracker</h1>
      <div>Where you enable the Usage Tracker functionality, data is collected from your Device and generated by the App about your vaping consumption and behaviours when you use your Device, including puff count, puff power, puff length, and other usage statistics.</div><br />
      <li>Pod Tracker</li>
      <div><div>Data will also be collected regarding your use of pods; this includes your purchase of pods from the e-commerce store.</div></div>
    </div>
    <LegalFooter></LegalFooter>
  </>
);

UsageTracker.propTypes = {};

UsageTracker.defaultProps = {};

export default UsageTracker;
