import React from 'react';
import './SmartAssistant.scss';

import './../../content-page.scss';

import vuseLogo from "./../../../images/vuseLogo.svg";
import LegalFooter from '../../../components/LegalFooter/LegalFooter';

const SmartAssistant = () => (
  <>
    <div id="content-page">
      <a href="/">
        <img src={vuseLogo} alt=""></img>
      </a>
      <h1>Smart Assistant</h1>
    Excluding device configuration automations,
    Smart Assistant will collect data in order to reach your pre-defined automated thresholds,
    if you have enabled the Usage Tracker and Pod Tracker feature.
  </div>
    <LegalFooter></LegalFooter>
  </>
);

SmartAssistant.propTypes = {};

SmartAssistant.defaultProps = {};

export default SmartAssistant;
