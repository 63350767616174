import React from 'react';
import './PrivacyPolicy.scss';

import './../../policies.scss';

import vuseLogo from "./../../../images/vuseLogo.svg";
import LegalFooter from '../../../components/LegalFooter/LegalFooter';

const PrivacyPolicy = () => (
  <>
    <div className="PrivacyPolicy">
      {getHTML()}
    </div>
    <LegalFooter></LegalFooter>
  </>
);

function getHTML() {
  return (
    <div id="policy">
      <a href="/">
        <img src={vuseLogo} alt=""></img>
      </a>
      <h1>MYVUSE Privacy Notice (UK Pilot Programme)</h1>

      <p >
        Welcome to the MYVUSE App (“App”). This Privacy Notice explains what we do
        with your personal information when you are using the App in connection
        with one of our Bluetooth enabled ePod devices (“Device”) as part of the UK
        pilot programme of the launch of our App and Devices which you have agreed
        to take part in (“Pilot Programme”). This Privacy Notice describes how we
        collect, use and process your personal information. Your privacy is
        important to us, and we are committed to protecting and safeguarding your
        rights.
</p>
      <p >
        For the purpose of applicable data protection legislation British American
        Tobacco UK Limited, whose registered office is at Building 7, Chiswick
        Business Park, 566 Chiswick High Road, London, W4 5YG, United Kingdom
        (“we”, “our” or “us”) is the ‘controller’ of your personal information.
        This means we decide why and how your personal information is used and are
        responsible for protecting it. Please refer to the end of this notice for
        our contact information.
</p>
      <p >
        We may amend this Privacy Notice from time to time by posting an updated
        version on the App. Where appropriate, we may also notify you of the
        changes to this Privacy Notice by email. Please also see further the
        Changes section below.
</p>
      <p >
        To use the App you must have a MYVUSE e-Commerce Account (“e-Commerce
        Account”) registered with us. If you already have an e-Commerce Account
        with us, you can use your e-Commerce login details to login and use the App
        (following age verification). If you do not have an existing e-Commerce
        Account, you will be asked to register for one. How we process your
        personal information in connection with your e-Commerce Account (including
        any purchases made through the App) is governed by a separate privacy
notice available    <a href="https://www.vuse.com/gb/en/privacy-notice">here</a>.
Once the Pilot Programme is completed we will close your e-Commerce Account, and delete all the information contained therein, unless you have registered prior to the Pilot Programme in which case your account will not be deleted.
</p>
      <h3 >
        INFORMATION WE COLLECT ABOUT YOU
</h3>
      <p >

      </p>
      <div>
        <p >
          We collect and use information about you in the course of your use of the
          App and Device as a participant of the Pilot Programme. This personal
          information may be collected:
          <br />
          <br />
          <li>directly from you, for example when you communicate with us;</li>
          <li>
            via the App, for example when the App synchronises with our cloud
            environment;
         </li>
          <li>
            via third party service providers or publicly available
            information, for example when we are collecting information for the
            purposes of age verification (see further Verifying Your Age
            below).
          </li>
          <br />
          Some of the personal information we collect is dependent upon the App
          functionalities that you have chosen in the App to enable and use. These
          App functionalities and the types of personal that may be collected are set
          out below.
  </p>
      </div>

      <h2 >
        Usage Tracker
</h2>
      <p>

        Where you enable the Usage Tracker functionality, data is collected from
        your Device and generated by the App about your vaping consumption and
        behaviours when you use your Device, including puff count, puff power, puff
        length, and other usage statistics.
</p>
      <ul>
        <h2 >
          <li >Pod Tracker</li>
        </h2>
      </ul>
      <p>
        <div>
          Data will also be collected regarding your use of pods; this includes your
          purchase of pods from the e-commerce store.
        </div>
      </p>
      <h2 >
        Find My Vape
      </h2>
      <div>
        <p>

          Where you enable the Find My Vape functionality, data is collected to
          enable you to see the last location where your Device was synced with the
          App, including GPS location of the last synchronisation, and timestamp of
          the last synchronisation between the Device and App.
          </p>
      </div>
      <h2 >
        Favourites
      </h2>
      <div>
        <p>
          When you create a Favourites list, we will collect information on your pod
          flavour preferences.
          </p>
      </div>
      <ul>

        <h2 >
          <li >Flavour For You</li>
        </h2>

      </ul>
      <div>
        <p>
          Where you enable the Flavour For You functionality, we collect information
          on your Favourites list, flavours previously ordered and flavours that you
          have asked us not to suggest. We will also send you regular communications
          (no more than once every two weeks) with suggestions of new flavours that
          we think you may enjoy. By enabling this functionality, you consent to us
          sending these communications. You can opt out of receiving these
          communications at any time by disabling the Flavour For You functionality.
          </p>
      </div>
      <ul>

        <h2 >
          <li >Order Reminder</li>
        </h2>

      </ul>
      <div>
        <p>
          This data will be collected from you in relation to the threshold of
          personal stock set by you and the basket of pods that you may wish to
          purchase through your e-Commerce Account once that threshold is met,
          including Favourites
          </p>
      </div>
      <h2 >
        Smart Assistant
</h2>
      <p >
        Excluding device configuration automations, Smart Assistant will collect
        data in order to reach your pre-defined automated thresholds, if you have
        enabled the Usage Tracker and Pod Tracker feature.
</p>
      <h2 >
        Other data
</h2>
      <p>We may also collect some or all of the personal information listed below.</p>
      <ul>
        <li >
          <b>Identity data:</b> information in connection with your login to the App
          and the age verification process will be collected, including name,
          email address, address and identification verification documents
          (e.g. government issued ID or driving licence), credit card details
          and photo.
        </li>
        <li >
          <b>Live Agent data:</b> we collect information from you when you contact
          us using the Live Agent function on the App. This will include your
          name, email address and information related to the reason you are
          contacting us. We will link any conversations you have with us to
          your e-Commerce Account.
        </li>
        <li >
          <b>App engagement data:</b> we collect information on how you have engaged
          with the App, including what features and functionalities you have
          used or enabled, and how the amount of time spent using those
          features and functionalities. If you access the App through a web
          application rather than a mobile/ tablet device, we may collect
          this through the use of first party cookies where you have agreed.
        </li>
        <li >
          <b>User terminal data:</b> we collect information in connection with the
          terminal you use to access the App, including IP address, terminal
          make and model, and operating system.
        </li>
        <li >
          <b>Device data:</b> we collect information specific to your Device,
          including a unique Device identifier and data related to the
          battery of your Device.
        </li>
        <li >
          <b>Event log data:</b> event log data will also be collected and recorded
          by the Device, including data related to Device restart
          information, errors, battery changes and Bluetooth connectivity.
          Event log data will not be linked to you as it is anonymised by the
          Device before being synced with the App.
        </li>
        <li >
          <b>Pilot Programme data:</b> we will ask you for certain information
          during the course of the Pilot Programme, including your feedback
          on the Device and App and information on your overall user
          experience provided through surveys and questionnaires, details on
          the pods you are consuming and where/how you purchase these, and
          any details of any adverse incident experience whilst using the
          Device that you disclose to us.
        </li>
        <li >
          <b>Segment data:</b> we may also collect information about your location
          (region-level only),your gender, your age (within a range) as part
          of a screening questionnaire carried out by a third party partner
          as part of your participation in the Pilot Programme. This
          information is only shared with us in an aggregated form, so we
          will not be able to identify you.
        </li>
        <li >
          <b>Other information that you provide us:</b> any extra information that
          you choose to tell us.
        </li>
      </ul>
      <p >
        We will not ordinarily have access to all of this personal information. See
        further <u>Where We Store This Information</u> below.
</p>
      <p >
        Depending on the type of personal information in question and the legal
        grounds (i.e. the ‘lawful bases’) on which we may be processing it, should
        you decline to provide us with such personal information, you may not be
        able to continue with your participation in the Pilot Programme.
</p>
      <p >
        In addition, we collect and/or generate anonymised and aggregated
        information from your use of the App. We use anonymised and aggregated
        information in various ways, including to understand App engagement and
        usage. Anonymised or aggregated information is not personal information.
</p>
      <p >
        <b>RETURN OF DEVICE</b>
      </p>
      <p >
        You will be required to return the Device at the end of the Pilot
        Programme. If there are any issues with the Device during the Pilot
        Programme, you may also be asked to return it to us before the end of the
        Pilot Programme (e.g. for product safety issues or forensic enquiries). The
        returned Device may also contain information such as your track my
        consumption data. However, we will not be able to link this back to you and
        so this is not personal information.
</p>
      <div>

        <h3 >
          HOW WE USE YOUR INFORMATION
  </h3>
      </div>
      <p >
        The purposes for which we use your personal information and the lawful
        basis under data protection laws on which we rely to do this are explained
        below.
</p>
      <p >
        <b>Where there is a legal requirement:</b> we will use your personal information
        to comply with our legal obligations, including where the law requires us:
</p>
      <ul>
        <li >
          <p >
            to identify you when you contact us or to authenticate you when
            logging into your account;
        </p>
        </li>
        <li >
          <p >
            to verify the accuracy of data we hold about you;
        </p>
        </li>
        <li >
          <p >
            to comply with a request from you in connection with the exercise
            of your rights;
        </p>
        </li>
        <li >
          <p >
            to carry out age verification checks (please see <u>Verifying Your Age</u>
            for further details).
        </p>
        </li>
      </ul>
      <p >
        Where there is a legitimate interest: we may use and process your personal
        information where it is necessary for us to pursue the following legitimate
        interests (whether ours, in connection with our business, or that of a
        third party), including for the following purposes and for the purposes of this Pilot Programme only:
</p>
      <ul>
        <li >
          <p >
            to provide you with the functionalities offered by the App,
            including Track My Consumption, Find My Vape, Order Reminder and
            Smart Assistant;
        </p>
        </li>
        <li >
          <p >
            to evaluate use of the App and Device during the Pilot Programme,
            so that we can improve the App and for Device development;
        </p>
        </li>
        <li >
          <p >
            to carry out analytics to better understand how our users use and
            engage with the App and improve the user experience;
        </p>
        </li>
        <li >
          <p >
            to administer the App and for internal operations related to the
            App and the Device, including troubleshooting, testing, App
            performance, Device errors;
        </p>
        </li>
        <li >
          <p >
            to respond to communications you send to us relating to the App and
            Device, and resolve any issues or queries you may have, including
            through use of the Live Agent function;
        </p>
        </li>
        <li >
          <p >
            to inform you of updates to our App terms and conditions and
            policies;
        </p>
        </li>
        <li >
          <p >
            for the prevention of fraud and other criminal activities;
        </p>
        </li>
        <li >
          <p >
            for network and information security in order for us to take steps
            to protect your information against loss or damage, theft or
            unauthorised access;
        </p>
        </li>
        <li >
          <p >
            to correspond or communicate with you in relation to
            administrative, legal and business matters;
        </p>
        </li>
        <li >
          <p >
            to enforce or protect our contractual or other legal rights or to
            bring or defend legal proceedings;
        </p>
        </li>
        <li >
          <p >
            to allow you to register, for your e-Commerce Account);
        </p>
        </li>
        <li >
          <p >
            for other general administration including managing your queries,
            complaints, or claims, and to send service messages to you in relation to the Pilot Programme
        </p>
        </li>
      </ul>
      <p >
        <b>
          Where it is required to complete or, at your request, take steps to enter
          into, a contract:
        </b> the use of your personal information may be necessary to
        perform a contract that you have with us or perform steps you request to
        enter into a contract. For example, when you enable the Order Reminder
        functionality of the App, we need to use your Track My Consumption data to
        estimate the consumption of liquid by you and, therefore, estimate when the
        threshold you have set will be reached and the communicate to you your
        reminder.
</p>
      <p >
        <b>Where we have obtained your consent:</b> where you have enabled the Flavour For
        You functionality and we send you communications on flavour suggestions, we
        rely upon your consent to do so. You provide this consent when you enable
        this functionality. You can withdraw your consent at any time by disabling
        this functionality in the App. Where you access the App through a web
        application, we also rely on consent to collect your personal information
        using first party cookies.
</p>
      <p >
        <b>Where it is in your vital interests:</b> we will use your personal information
        to notify you of any Device safety or recall issues.
</p>
      <h3 >
        VERIFYING YOUR AGE
</h3>
      <p >
        As the App relates to vaping, we are legally obliged to make sure that
        users are aged 18 years or over. Failing age verification will mean you
        cannot login and use the App.
</p>
      <p >
        In order to enable us to do this we will require certain information about
        you. This will then be passed to our service provider Jumio who will check it
        against public sources of information to deliver confirmation, usually in
        real time. In order to verify your identity, we may also require you to
        provide demographic information (such as your gender and birth date) and
        other personal information which may be used by us and our service provider
        to verify your details and may include your passport number or driving
        licence number. This information is checked against secure independent data
        sources, such as the electoral roll, in order to help verify your identity.
        This information is deleted immediately by the service provider once your age has been verified.
</p>
      <p >
        In some cases, we may need to ask for further information in order to
        verify your age. If this is necessary, we will contact you to explain why.
</p>
      <p >
        <b><u>SHARING YOUR INFORMATION WITH THIRD PARTIES</u></b>
      </p>
      <p >
        We may share your personal information with any of the following groups and
        will always only do so in accordance with applicable data protection rules:
</p>
      <ul>

        <li >
          <p >
            <b>Tax, audit, or other authorities:</b> we may share your personal
            information with tax, audit or other authorities.
        </p>
        </li>
        <li >
          <p >
            <b>Third party analytics providers:</b> we may share your personal
            information with third party analytics providers who help us
            understand how our users use and engage with the App.
        </p>
        </li>
        <li >
          <p >
            <b>Third party IT service providers.</b> we may share your personal
            information with third party outsourced IT providers where we have
            an appropriate data processing agreement (or similar protections)
            in place or IT technical support functions, IT consultants and
            third-party analytics service providers who carry out testing,
            research and development work on our business technology systems.
        </p>
        </li>
        <li >
          <p >
            <b>Other third party service providers:</b> we may share your personal
            information with other third party service providers and
            professional advisors, including lawyers who provide us with legal
            and regulatory advice, external consultants who provide industry
            insights, market research and technical support and third parties
            who provide consumer support services.
        </p>
        </li>
        <li >
          <p >
            <b>Comply with law or protect our rights:</b> we may share your personal
            information with third parties to comply with legal or regulatory
            requirements (for example, for age verification purposes), or if we
            have to enforce or apply our App Terms and Conditions or any other
            agreements or to protect our rights, property or our consumers,
            etc.
        </p>
        </li>
      </ul>
      <h3 >
        WHERE WE STORE YOUR INFORMATION
</h3>
      <p >
        Generally, your personal information will be stored in the environment of
        the Device and App and will not be accessible to us. However, exceptions to
        this are the Track My Consumption data and the Segment data. This
        information will be received and stored by us.
</p>
      <p >
        Your personal information may also be transferred outside of the UK to the
        third parties described in Sharing Your Information With Third Parties. We
        want to make sure that your personal information is stored and transferred
        in a way which is secure. We will therefore only transfer data outside of
        the UK where it is compliant with data protection legislation and the means
        of transfer provides adequate safeguards in relation to your data, for
        example:
</p>
      <ul>
        <li >
          <p >
            by transferring your data to a country where there has been a
            finding of adequacy under applicable data protection legislation,
            including by the UK Information Commissioner’s Office or other
            competent body in respect of the UK’s level of data protection via
            its legislation;
        </p>
        </li>
        <li >
          <p >
            by way of an intra-group agreement between BAT entities,
            incorporating the incorporating the standard contractual clauses
            adopted or approved under applicable data protection laws for the
            transfer of personal information to jurisdictions without adequate
            data protection laws;
        </p>
        </li>
        <li >
          <p >
            by way of a data transfer agreement with a third party,
            incorporating the standard contractual clauses adopted or approved
            under applicable data protection laws for the transfer of personal
            information to jurisdictions without adequate data protection
            laws;.
        </p>
        </li>
      </ul>
      <p >
        Where we transfer your personal information outside the UK and where the
        country or territory in question does not maintain adequate data protection
        standards, we will take all reasonable steps to ensure that your data is
        treated securely and in accordance with this Privacy Notice. You can ask to
        see these by contacting us using the contact details below.
</p>
      <h3 >
        HOW WE SAFEGUARD YOUR INFORMATION
</h3>
      <p >
        We care about protecting your information. That's why we put in place
        appropriate measures that are designed to prevent unauthorised access to,
        and misuse of, your personal information. We are committed to taking all
        reasonable and appropriate steps to protect the personal information that
        we hold from misuse, loss, or unauthorised access. We do this by having in
        place a range of appropriate technical and organisational measures,
        including pseudonymisation, intrusion detection, and access control
        measures.
</p>
      <p >
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we will apply our normal procedures and comply
        with legal requirements to protect your information, we cannot guarantee
        the security of your information transmitted to the App and any
        transmission is at your own risk.
</p>
      <h3 >
        HOW LONG WE KEEP YOUR INFORMATION
</h3>
      <p >
        Generally we will retain your personal information for as long as you
        continue to use the App and Device and participate in the Pilot Programme,
        and for a period after you discontinue using the App and Device. When
        determining how long this retention period will last, we take into account
        factors including the length of time personal information is required for
        the legitimate interests we outlined above, or whether retention is
        required by law or other regulation (for example, in connection with any
        anticipated litigation).
</p>
      <p >
        When it is no longer necessary to retain your data, we will delete the
        personal information that we hold about you from our systems (either by
        erasing or anonymising that data). After that time, we may retain
        aggregated data (from which you cannot be identified) and retain it for
        analytical and statistical purposes.
</p>
      <p >
        <b>YOUR RIGHTS</b>
      </p>
      <p >
        You have a number of rights in relation to your information under data
        protection law. In relation to certain rights, we may ask you for
        information to confirm your identity and, where applicable, to help us to
        search for your personal information. Subject certain exceptions, we will
        respond to you within one month from either: (i) the date that we have
        confirmed your identity; or (ii) where we do not need to do this because we
        already have this information, from the date we received your request.
        These rights include:
</p>
      <ul>
        <li >
          <p >
            <b>Right to access your personal information:</b> You have the right to
            request a copy of the personal information that we hold about you,
            and request us to modify, update or delete such information.
            However, you should be aware that where we are legally permitted to
            do so, we may refuse your request. If we refuse your request we
            will always tell you the reasons for doing so. You can also access
            much of your personal information by logging into the App.
        </p>
        </li>
        <li >
          <p >
            <b>Right to object to, and/or restrict, processing:</b> You have the right
            to object to, or restrict our processing of, your personal
            information in certain circumstances. We will stop such processing
            unless we can demonstrate compelling legitimate grounds for the
            processing which overrides your interests or if the processing is
            necessary for the establishment, exercise or defence of legal
            claims. You can also restrict or limit much of your information by
            logging into the App and disabling some or all App functionalities.
        </p>
        </li>
        <li >
          <p >
            <b>Right to erasure:</b> You have the right to request that we erase your
            personal information in certain circumstances. We would only be
            entitled to refuse to comply with your request for erasure in
            limited circumstances and we will always tell you our reason for
            doing so. When complying with a valid request for the erasure of
            data we will take all reasonably practicable steps to delete the
            relevant data.
        </p>
        </li>
        <li >
          <p >
            <b>Right to rectification:</b> You have the right to request that we
            rectify any inaccurate or incomplete personal information that we
            hold about you. You can also rectify certain of your personal
            information by logging into the App.
        </p>
        </li>
        <li >
          <p >
            <b>Right of data portability:</b> You have the right to transfer your
            personal information between service providers where we rely on
            your consent or the performance of your contract as the lawful
            basis to use that information.
        </p>
        </li>
        <li >
          <p >
            <b>Right not to be subject to automated decision making:</b> You have the
            right not to be subject to a decision based solely on automated
            processing including profiling which produces legal effects on you
            or similarly affects you.
        </p>
        </li>
        <li >
          <p >
            <b>Right to withdraw consent:</b> Where we have obtained your consent to
            process your personal information for certain activities, you may
            withdraw this consent at any time by contacting us on the details
            in the ‘Contact Us’ section below.
        </p>
        </li>
        <li >
          <p >
            <b>Right to complain:</b> You also have the right to lodge a complaint and
            can contact us using the details below in the ‘Contact Us’ section.
            You also have the right to make a complaint to your local
            supervisory authority, which in the United Kingdom is the
Information Commissioner’s Office (            <a href="http://www.ico.org.uk">www.ico.org.uk</a>).
        </p>
        </li>
      </ul>
      <div>
        <h3 >
          CHANGES
  </h3>
      </div>
      <p >
        We may make changes to this Privacy Notice at any time by posting a copy of
        the modified notice on the App. Please visit the relevant section of the
        App regularly. Where appropriate, we may send you an email notifying you of
        any changes. Any changes will take effect on the date on which we post the
        modified notice on the App.
</p>
      <div>
        <h3>
          HOW TO CONTACT US
  </h3>
      </div>
      <p >
        If you have any queries about this Privacy Notice, including your rights in
        relation to your personal information, please contact Head of Legal and
        External Affairs or by post at:
</p>
British American Tobacco UK Limited
      <br />
Building 7,
      <br />
Chiswick Business Park,<br />
566 Chiswick High Road,<br />
London, W4 5YG<br /><br />
If you wish to contact us with any general queries or concerns, you can use the Contact Us functionality in the App or email us at info.uk@vuse.com or write to us via post at Customer Services:<br /><br />
Vuse Customer Services<br />
Building 7,<br />
Chiswick Business Park,<br />
566 Chiswick High Road,<br />
London, W4 5YG<br />

      <p >
        <br />
    When contacting us by email or post, please use the subject heading ‘Data
    protection query’ so that we can direct your query to the appropriate
    department and deal with it promptly.
</p>
      <div>
        <br />
      </div>
    </div>
  )
}

PrivacyPolicy.propTypes = {};

PrivacyPolicy.defaultProps = {};

export default PrivacyPolicy;
