import React from 'react';
import './Footer.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

import myVuseLogo from './../../images/desktop/My-Vuse-footer@2x.png';

const Footer = () => (
  <div className="footer footer-top">
    <div className="my-vuse-logo">
      <img src={myVuseLogo} alt=""></img>
    </div>
    <hr className="horizontal-line"></hr>
    <div className="contact-us-group">
      <div className="contact-us">
        CONTACT US
    </div>
      <p className="contact-us-subtitle">
        Want to speak to one of our agents? Call us on
    </p>
      <div className="phone-number">
        <FontAwesomeIcon icon={faPhoneAlt} />
        <a href="tel:0800 133 7350">0800 133 7350</a>
      </div>
      <div id="schedule" className="contact-us-subtitle">
        Monday to Friday 9am to 5pm<br /> excluding public holidays.<br /><br />Pleae have your device ID ready.
    </div>
    </div>
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
