import React from 'react';
import './CookiePolicy.scss';

import './../../policies.scss';

import vuseLogo from "./../../../images/vuseLogo.svg";
import LegalFooter from '../../../components/LegalFooter/LegalFooter';

const CookiePolicy = () => (
  <>
    <div id="CookiePolicy">
      {getHTML()}
    </div>
    <LegalFooter></LegalFooter>
  </>
);

function getHTML() {
  return (
    <div id="policy">
      <a href="/">
        <img src={vuseLogo} alt=""></img>
      </a>
      <h1>MYVUSE<br />COOKIES NOTICE<br /></h1><br />
      <em><p>This cookie Notice describes the cookies that may be set when you visit our Web App and the purposes for which we use those cookies and any personal information collected by using them.</p></em>
      <br />
      <b>1 OUR USE OF COOKIES</b><br /><br />
      <div>
        1.1 Welcome to the MYVUSE web app (<b>Web App</b>), which is owned and operated by British American Tobacco UK Limited, whose registered office is at Building 7, Chiswick Business Park, 566 Chiswick High Road, London, W4 5YG, United Kingdom (referred to in this Notice as <b>we</b>, <b>us</b> or <b>our</b>).
        <br /><br />1.2 This Cookies Notice describes the cookies that are automatically set and others that you may choose to set when you visit our Web App and the purposes for which we use them.
        <br /><br />1.3 Our Web App uses cookies to distinguish you from other users. This helps us to provide you with a good experience when you browse our Web App and also allows us to improve our Web App.
        <br /><br />1.4 Some cookies work by collecting and storing some form of personal information about you, so this Notice also describes the personal information we process or allow others to access when using cookies.
        <br /><br />1.5 For further information on how we use the personal information we collect about you (including information collected through our use of cookies) please see our Privacy Notice.
        <br /><br />1.6 This Cookies Notice was last updated on the date set out at the end of this Notice.
      </div>
      <br />
      <b>2 WHAT ARE COOKIES?</b><br /><br />
      <div>
        2.1 A cookie is a small data file that is placed on your device to allow a Web App to recognise you as a unique user when you use the Web App, either for the duration of your visit (using a <b>session cookie</b>) or for repeat visits (a <b>persistent cookie</b>). Other similar files such as pixels and beacons work in the same way and we use the word &lsquo;cookie&rsquo; in this Cookies Notice to refer to all technologies that collect information in this way. Cookies can also be used in emails you receive to help understand your level of engagement with them.
        <br /><br />2.2 Cookies can be '<b>first party</b>' (which are set by us) or '<b>third party</b>' (which are placed on your device by another Web App when you use our Web App).

        <br /><br />2.3 Cookies are a common technology for remembering certain information about a visitor to a Web App. The vast majority of Web Apps currently make use of cookies and they are commonly used for a wide range of tasks. We use the following types of cookies for the following purposes:

        <br /><br />2.3.1 <i><b >Browser cookies.</b></i> We use cookies set via your web browser for the following purposes:<br />
        <li><u><b >Strictly necessary cookies.</b></u> These cookies are necessary for our Web App to function and cannot be switched off in our systems. They include, for example, a cookie that is set for security reasons or to remember your cookie consent preferences. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences.  You can set your browser to block or alert you about these cookies, but some parts of our Web App will then cease to work.</li>
        <br /><li><u><b >Functional cookies.</b></u> These cookies enable our Web App to provide enhanced functionality and personalised features. They may be set by us or by third party providers whose services we have added to our pages.  If you do not allow these cookies then some or all of these services may not function properly.</li>
        <br /><li><u><b >Performance / Analytics cookies.</b></u> These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our Web App. They help us to know which pages are the most and least popular and see how visitors move around our Web App.  All information these cookies collect is aggregated after it is collected. If you do not allow these cookies we will be unable to measure traffic to our Web App and unable to monitor its performance.</li>


        <br />2.2.3 <u><b>Device data</b></u>. We obtain data relating to your device, as described in our Privacy Notice, for the purposes set out in the <b><em>How we use your information and our legal basis</em></b> section in that notice.
      </div>
      <br /><b>3 CONSENTING TO THE USE OF COOKIES AND THE PERSONAL INFORMATION THEY COLLECT</b><br />
      <div>
        <br />3.1 Certain cookies we use are strictly necessary for our Web App to work. For example, when you visit our Web App for the first time, we present you with a cookie notice and tool (<b>Cookie Tool</b>) requesting your consent for non-essential cookies. We have to use a cookie to remember your cookie preferences in order to comply with the law (you can see details of this cookie in the table below).
        <br /><br />3.2 When you first visit our Web App, we automatically set essential cookies on your device that are strictly necessary for our Web App to work. In relation to all other types of cookies we wish to use, you will be invited to consent to our use of them using our Cookie Tool. If you do not provide your consent using the Cookie Tool, we will not set these cookies on your device.
        <br /><br />3.3 If you do not provide your consent, our Web App will still work but some of the enhanced and personalised features may not.
        <br /><br />3.4 If you provide your consent in respect of cookies, you will also be providing your consent for us and the relevant third parties that use those cookies to use your personal information in the ways set out in our Privacy Notice. We will only set or read the relevant cookies for the durations set out in the table below. You can withdraw your consent at any time by using the Cookie Tool to turn off/opt-out of the relevant cookies. The cookie files may remain on your device after you have opted-out of using them. Please see the <b>How to control and delete cookies</b> section of this Notice or check your browser&rsquo;s help pages to find out how to delete these files from your device.
        <br /><br />3.5 If you withdraw your consent for us to use non-essential cookies, the consent you have given us to use any personal information we collect using those cookies will also be withdrawn. Please see our Privacy Notice for further details.
      </div>
      <br /><b>x4 COOKIES USED ON OUR WEB APP</b><br />
      <div>
        <br />4.1 The table below sets out the first party and third party cookies that are set and read on your device. The legal reason (also known as the &lsquo;lawful basis&rsquo;) for using the personal information collected using the cookies is also set out in the table. To find out more about the lawful bases on which we rely to use your personal information, please see our Privacy Notice.
        <br /><br />4.2 Some third party cookies are set by third parties that provide a service to us and who act on our behalf and on our instructions. Our <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Notice</a> applies to the personal information collected using these cookies.
        <br /><br />Other third parties are companies who use the personal information for their own business purposes and are, for the purposes of data protection law, &lsquo;controllers&rsquo; in their own right. Whilst we provide a summary of the third party cookies for these companies in the table below, you should also check the relevant third party&rsquo;s privacy Notice using the links in that table for more information about how these cookies and the personal information they collect are used.
        <br /> <br />
        <table >
          <tr>
            <th><b>Name</b></th>
            <th><b>Duration</b></th>
            <th><b>First/Third Party & Purpose</b></th>
            <th><b>Category</b></th>
            <th><b>Lawful basis for personal information collected by cookie</b></th>
          </tr>
          <tr>
            <td className="square">App Data</td>
            <td>Session</td>
            <td>
              <b>First Party</b>
              <p>The localStorage read-only property of the window interface allows you to access a Storage object for the Document's origin; the stored data is saved across browser sessions. </p>
            </td>
            <td>Strictly Necessary</td>
            <td>Legal Obligation</td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>2 years</td>
            <td><b>Third Party</b>
              <p>
                Google (Analytics service provider)
  <br /><br />
  These cookies are used to collect information about how visitors to our Website use our Website, which can help us to improve it. Types of information include the number of visitors to our Website, where visitors have come from to reach our Website and pages they have viewed on our Website.
  <br /><br />
  Please see the <b>Google Analytics</b> section below for more information
            </p>
            </td>
            <td>Analytics</td>
            <td>Consent</td>
          </tr>
          <tr>
            <td>ki_t</td>
            <td>5 years</td>
            <td><b>Third Party</b>
              <p>
                This cookie is provided by the Qualaroo. The cookie is used to run surveys on the websites. This cookie stores the timestamps and view count.</p>
            </td>
            <td>Analytics</td>
            <td>Consent</td>
          </tr>
          <tr>
            <td>ki_r</td>
            <td>5 years</td>
            <td><b>Third Party</b>
              <p>This cookie is provided by the Qualaroo. The cookie is used to run surveys on the websites. This cookie log the initial document referrer when available for targeting purposes. </p>
            </td>
            <td>Analytics</td>
            <td>Consent</td>
          </tr>
          <tr>
            <td>_cs_id</td>
            <td>13 months</td>
            <td><b>Third Party</b>
              <p>Content Square: used to store user identifier.</p>
            </td>
            <td>Analytics</td>
            <td>Consent</td>
          </tr>
          <tr>
            <td>_cs_s</td>
            <td>30 minutes</td>
            <td><b>Third Party</b>
              <p>Content Square: number of pageviews within the running session.</p>
            </td>
            <td>Analytics</td>
            <td>Consent</td>
          </tr>
          <tr>
            <td>_cs_vars</td>
            <td>Session</td>
            <td><b>Third Party</b>
              <p>Content Square: used to record custom variables</p>
            </td>
            <td>Analytics</td>
            <td>Consent</td>
          </tr>
          <tr>
            <td>_cs_c</td>
            <td>Session</td>
            <td><b>Third Party</b>
              <p>Content Square: used to record custom variables</p>
            </td>
            <td>Analytics</td>
            <td>Consent</td>
          </tr>
        </table>

      </div>
      <br />

      <b>5 GOOGLE ANALYTICS</b><br />
      <div>
        5.1 Our Web App uses Google Analytics, a web analytics service provided by Google. Google Analytics uses cookies to analyse the use of our Web App by you and other visitors. The information generated by the cookie about the use of our Web App is usually transferred to a server of Google in the United States where it is stored. Please see our <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Notice</a> for further information regarding personal information transferred overseas.
        5.2 We have activated internet protocol (IP) address anonymisation for Google Analytics so that your IP address is shortened in advance of transfer to Google if you are located in the United Kingdom or European Economic Area. Only in exceptional cases will the full IP address be transferred to a Google server in the United States and shortened there. Google will use this information on our behalf to evaluate the use of our Web App by you and other users, to compile reports on the activities on our Web App and for further purposes related to the use of our Web App to provide services to us.
        5.3 The IP address submitted by your browser to Google Analytics will not be merged with other Google data. Please see our <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Notice</a> for details of the personal information that we collect from you using the Google Analytics service.
      </div>
      <br />
      <b>6 EMBEDDED CONTENT</b><br />
      <div>
        6.1 We may embed content from third party providers within our MYVUSE Web App.
        <br /><br />6.2 Please see the following privacy notices for more information:
        <div>

          <br />6.2.1 Qualaroo <a href="https://qualaroo.com/privacy-policy/" target="_blank" rel="noreferrer">https://qualaroo.com/privacy-policy/</a>
          <br /><br />6.2.2 Content Square - <a href="https://contentsquare.com/privacy-center/" target="_blank" rel="noreferrer">https://contentsquare.com/privacy-center/</a>
        </div>

      </div>
      <br />
      <b>7 HOW TO CONTROL AND DELETE COOKIES</b><br />
      <div>
        <br />7.1 You can change your cookie preferences at any time by deleting cookies using your browser and refreshing any page on our Web App or by using our Cookie Tool.
        <br /><br />7.2 You may wish to visit <a href="http://www.allaboutcookies.org/" target="_blank" rel="noreferrer">http://www.allaboutcookies.org/</a> which contains comprehensive information on how to do this on a wide variety of browsers. You will also find details on how to delete cookies from your device, as well as more general information about cookies. Please note that, as these Web Apps are not owned or operated by us, we are not responsible for any of the content on them.
        <br /><br />7.3 In relation to Google Analytics cookies, you can also use the specific Google opt-out tool at <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout</a>.
      </div>
      <br /><b>8 CHANGES</b><br />
      <br /><div>We may make changes to this Cookies Notice at any time by posting a copy of the revised Notice on our Web App. Any changes will take effect 7 days after the date on which we post the modified terms on our Web App, whichever is the earlier.</div>
      <br /><b>9 CONTACT US</b><br />
      <br /><div>
        If you have any questions or concerns about cookies or your privacy when using our Web App, please use our contact details in the How to contact us section of our Privacy Notice.
        <br />Last updated: 07 June 2021
      </div>
    </div >
  )
}

export default CookiePolicy;
