import React from 'react';
import './ConditionsOfSale.scss';

import './../../policies.scss';

import LegalFooter from '../../../components/LegalFooter/LegalFooter';
import vuseLogo from "./../../../images/vuseLogo.svg";

const ConditionsOfSale = () => (
  <>
    <div className="ConditionsOfSale">
      {getHTML()}
    </div>
    <LegalFooter></LegalFooter>
  </>
);

ConditionsOfSale.propTypes = {};

ConditionsOfSale.defaultProps = {};

function getHTML() {
  return (
    <div id="policy">
      <a href="/">
        <img src={vuseLogo} alt=""></img>
      </a>
      <h1>TERMS &amp; CONDITIONS OF SALE<br />
      </h1>
      <p>
        <b>1. Introduction</b>
        <br />
        <div>
          1.1 <b>What these terms cover:</b> These Terms and Conditions of Sale ("Terms"), together with all information and documents referred to in them, govern all sales of products ("<b>Products</b>") by us to you through this mobile application (the &ldquo;<b>App</b>&rdquo;) and through this App but via our website at www.vuse.com/gb/en (the "<b>Website</b>") (collectively, the &ldquo;<b>Online Services</b>&rdquo;).<br /><br />
1.2 <b>Why you should read them: Please make sure you have read these Terms carefully, especially the limitations of our liability, before placing an order</b>. These terms tell you who we are, how we will provide products to you, how you and we may change or end the contract, what to do if there is a problem and other important information. If you think that there is a mistake in these terms, please contact us to discuss.<br /><br />
1.3 <b>Acceptance of these terms</b>: By placing an order for a Product through our Online Services, you agree to and accept these Terms. You should print a copy of these Terms or save them for future reference.<br /><br />
1.4 <b>Eligibility</b>: To register with and purchase Products via our Online Services, you must be aged 18 years or over and have a registered postal address in the UK.<br /><br />
1.5 <b>We may amend these Terms from time to time:</b> Every time you order a Product, please check these Terms to ensure you understand the terms which will apply at that time. These Terms were most recently updated on 23rd March 2021.<br /><br />
1.6 <b>Online Services terms of use:</b> Your use of our App is governed by our App Terms and Conditions. Your use of our Website is governed by our Website Terms and Conditions. Please read these as they include important terms that apply whenever you use our Online Services.<br /><br />
        </div>
        <b>2. Information About Us</b>
        <br />
        <div>
          2.1 <b>Who we are:</b> When you purchase a Product through our &nbsp;Online Services you will be contracting with British American Tobacco UK Limited t/a Vuse, a limited company registered in England and Wales under company number 02346565 with its registered office at Building 7, Chiswick Business Park, 566 Chiswick High Road, London, W4 5YG. ("Vuse", "we", "our" or "us").<br /><br />
2.2 <b>What is our VAT Number:</b> Our VAT registration number is 239136950.<br /><br />
2.3 <b>How to contact us:</b> To contact us, please see our Contact Us page or email us at info.uk@vuse.com or write to us via post at Customer Services, British American Tobacco UK, Building 7, Chiswick Business Park, 566 Chiswick High Road, London, W4 5YG.<br /><br />
          2.4 <b>How we may contact you:</b> If we have to contact you we will do so by telephone or by writing to you at the email address or postal address you provided to us in your order. "Writing" includes emails. When we use the words "writing" or "written" in these Terms, this includes emails.<br /><br />
        </div>
        <b>3. Our Products</b>
        <br />
        <div>
          3.1 <b>Products may vary slightly from their pictures:</b> The Products are as described on our Online Services from time to time. Please note that the Products and any packaging on our Online Services are for illustrative purposes only. Slight variations may occur between the delivered Product and packaging, and the image of the Product and packaging given on our Online Services. You should ensure that you have checked the Product description on our Online Services before placing your order.<br /><br />
        </div>
        <b>4. How To Order</b>
        <br />
        <div>
          4.1 <b>We only sell to 18+ in the UK:</b> We only accept orders from people aged 18 years or over to a registered postal address in the UK.<br /><br />
4.2 <b>Debit/credit card required:</b> In order to place an order, you must be the holder of a valid debit/credit card or PayPal account and provide the details (please see clause 10 "Creating an Account" below).<br /><br />
4.3 <b>Ordering process:</b> You may place an order for a Product via our online order process on our Online Services. Our Online Services contain instructions on how to complete the order process and check and amend any errors before submitting your order to us. At the end of the order process you will be asked to provide payment through the on-line payment facility. Your order will be submitted to us when you click on the "Make Payment" button at the end of the order process. Your order represents an offer to us to purchase a Product.<br /><br />
4.4 <b>How we will accept your order:</b> After you place your order, we will send a confirmation email to you to accept your order, at which point a contract will come into existence between us.&nbsp;<br /><br />
4.5 <b>Taking payment:</b> By placing an order, you authorise us to immediately charge your payment card for the purchase price and we are entitled to rely on your placing of an order as an instruction to us to take your payment.<br /><br />
4.6 <b>If we cannot accept your order:</b> If we are unable to accept your order, we will inform you of this and will not charge you for the Product. This might be because the Product is out of stock, because of unexpected limits on our resources which we could not reasonably plan for, because we have identified an error in the price or description of the Product or because we are unable to meet a delivery deadline you have specified.<br /><br />
4.7 <b>Products are subject to availability:</b> All Products shown on our Online Services are subject to availability. We will inform you by email as soon as possible if the Product you have ordered is not available and will not process your order.<br /><br />
        </div>
        <b>5. Prices</b>
        <br />
        <div>
          5.1 <b>We will pass on the price of VAT and delivery:</b> All prices stated are in pounds sterling and include VAT. Delivery charges will be indicated to you at the time of ordering and will be added to the purchase price before you confirm your order.<br /><br />
5.2 <b>Where to find the price:</b> The price of a Product is as published on our Online Services at the date we accept your order. Prices are subject to change without notice at any time, but changes will not affect any order that we have previously accepted. However, if the rate of VAT changes between the date of your order and the date of delivery, we will adjust the VAT you pay, unless you have already paid for the Products in full before the change in VAT takes effect.<br /><br />
5.3 <b>What happens if we got the price wrong:</b> It is possible that, despite our reasonable efforts, a Product on our Online Services may be incorrectly priced. If we discover an error in the price of a Product that you have ordered we will inform you of this error and give you the option to continue with your order at the correct price or to cancel your order. If you do not respond to us within 7 days, we will treat the order as cancelled and notify you in writing.<br /><br />
        </div>
        <b>6. How To Pay</b>
        <br />
        <div>
          6.1 <b>How to pay:</b> You may pay for the Product with a debit or credit card, using your PayPal account or any other payment method that we indicate to you that we will accept from time to time, using the online payment facility. The payment facility is operated by Worldpay. Your use of the payment facility will be subject to their terms and conditions. You should ensure that you have read and agree with those terms and conditions before using the payment facility.<br /><br />
6.2 Worldpay operates a secure server to process your payment details. They encrypt your credit or debit card information and authorise payment directly. This means that your full credit card or debit card details are never revealed to us. To understand how our payment processor uses your information, we recommend that you read their privacy policy: http://www.worldpay.com/uk/privacy-policy. We will only keep the last four digits of your credit or debit card for our records and to create a more streamlined shopping experience.<br /><br />
6.3 <b>Authority for payment:</b> We will request authority for payment from your card at the time you place your order. If we fail to receive authority for your payment, or if we reasonably believe that payment will be refused, we reserve the right to reject your order.<br /><br />
6.4 In the event of a failed payment for whatever reason, you agree to compensate us in full against all reasonable costs, expenses and outgoings we incur in attempting to obtain payment made by you.<br /><br />
6.5 <b>No VAT receipts:</b> Please note that we do not issue VAT receipts for Products ordered from our Online Services.<br /><br />
        </div>
        <b>7. Delivery &amp; Postage</b>
        <br />
        <div>
          7.1 <b>UK only:</b> Delivery of a Product will only be made to a UK (including Northern Ireland) postal address.<br /><br />
7.2 <b>Delivery cost and method:</b> All products that you order will be sent by Yodel on our Standard Delivery, at no extra cost in 2 &ndash; 5 working days. You may request delivery by the Yodel Next Day Delivery service from the options on our Online Services. Please ensure if you chose this option, that you place your order by 4pm Monday to Thursday. You may also request delivery by the Yodel Saturday Delivery service from the options on our Online Services. Please ensure if you select this option, that you place your order by 4pm Monday to Friday. The use of the Next Day and Saturday Delivery services will be charged to you at the extra cost displayed on our Online Services before you order.<br /><br />
7.3 <b>When we will provide the Products:</b> We will indicate our estimated dispatch timings on our Online Services. Please note that the dispatch timings indicated on our Online Services are estimates only. &nbsp;The timings are not guaranteed times. We will send you an email confirming when your Product has been dispatched which will be within 30 days after the date on which we accept your order.<br /><br />
7.4 <b>We are not responsible for delays outside of our control:</b> If our supply of the Products is delayed by an event outside our control we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. However, if you have not received your Product within 5 working days of the date we confirm dispatch, please notify us and we will resend your order to you at no additional cost. We may, at our reasonable discretion, require proof of non-delivery, such proof to be obtained at your cost.<br /><br />
7.5 <b>When you become responsible for the Products:</b> The Products will be your responsibility once they have been delivered to the address indicated in your order.<br /><br />
7.6 <b>When you own the Products:</b> You will own the Products once we have received payment from you in full.<br /><br />
7.7 <b>If you are not at home when the Product is delivered:</b> If no one is available at your address to take delivery and the Products cannot be posted through your letterbox, we will leave you a note informing you of how to rearrange delivery or collect the Products from a local depot.<br /><br />
7.8 <b>If you do not re-arrange delivery:</b> If you do not re-arrange delivery or collect the Products from a delivery depot we will contact you for further instructions and may charge you for storage costs and any further delivery costs. If, despite our reasonable efforts, we are unable to contact you or re-arrange delivery or collection we may end the contract. &nbsp;<br /><br />
7.9 <b>Reasons we may suspend the supply of Products to you</b>. We may have to suspend the supply of a Product to:<br /><br />
          <div>
            a) deal with technical problems or make minor technical changes;<br /><br />
  b) update the Product to reflect changes in relevant laws and regulatory requirements;<br /><br />
  c) make changes to the Product as requested by you or notified by us to you.<br /><br />
          </div>
        </div>
        <b>8. Your Right TO END THE CONTRACT</b>
        <br />
        <div>
          8.1 <b>You can always end your contract with us:</b> Your rights when you end the contract will depend on what you have bought, whether there is anything wrong with it and when you decide to end the contract:<br /><br />
          <div>
            a) <b>If what you have bought is faulty or misdescribed you may have a legal right to end the contract</b> (or to get the product repaired or replaced or to get some or all of your money back), see clause 9;<br /><br />
  b) <b>If you want to end the contract because of something we have done or have told you we are going to do</b>, see clause 8.2;<br /><br />
  c) <b>If you have just changed your mind about the product</b>, see clauses 8.3 and 8.4. You may be able to get a refund if you are within the cooling-off period, but this may be subject to deductions and you will have to pay the costs of return of the Products;<br /><br />
  d) <b>In all other cases</b> (if we are not at fault and there is no right to change your mind), see clause 8.8.<br /><br />
          </div>
8.2 <b>Ending the contract because of something we have done or are going to do:</b> If you are ending a contract for a reason set out at (a) to (e) below the contract will end immediately and we will refund you in full for any Products which have not been provided and you may also be entitled to compensation. The reasons are:<br /><br />
          <div>
            a) we have told you about an upcoming change to the Product or these Terms which you do not agree to;<br /><br />
  b) we have told you about an error in the price or description of the Product you have ordered and you do not wish to proceed (see clause 5.3);<br /><br />
  c) there is a risk that supply of the Products may be significantly delayed because of events outside our control; or<br /><br />
  d) you have a legal right to end the contract because of something we have done wrong (including because we have delivered late).<br /><br />
          </div>
8.3 <b>Exercising your right to change your mind (Consumer Contracts Regulations 2013):</b> You have a legal right to change your mind within 14 days of receiving a Product and to receive a refund. &nbsp;<br /><br />
8.4 <b>When you don't have the right to change your mind:</b> You do not have a right to change your mind in respect of any Products sealed for health protection or hygiene purposes, once these have been unsealed after you receive them; and<br /><br />
8.5 <b>How long do you have to change your mind:</b> You have 14 days after the day you (or someone you nominate) receives the Products, unless your Products are split into several deliveries over different days. In this case you have until 14 days after the day you (or someone you nominate) receives the last delivery to change your mind about the Products.<br /><br />
8.6 <b>How to cancel the contract:</b> To cancel the contract, you need to notify us in writing. You can do this by email, by sending us a letter by post. To meet the cancellation deadline, you must send your notification concerning your exercise of the right to cancel before the cancellation period has expired. Please keep a copy of your cancellation notification for your own records.<br /><br />
8.7 <b>Returning products after exercising your right to change your mind:</b> If the Products have already been dispatched to you or you have already received them, you must return them to us without undue delay and in any event not later than 14 days from the day on which you communicate your cancellation of this contract to us. You will have to bear the direct cost of returning the Products to us.<br /><br />
8.8 <b>When we will refund you:</b> If you are exercising your right to change your mind and cancel the contract, you will receive a full refund of the price you paid for the Products and any applicable delivery charges (see clause 8.9(b) below) to the credit or debit card or other payment method that you used to pay. We will process the refund without delay and in any event not later than (a) 14 days after the day we receive back from you any Products supplied; or (b) if there were no Products supplied, 14 days after the day on which we receive your cancellation notification. We may withhold the refund until we have received the Products back or you have supplied evidence of having returned the Products to us (whichever is the earliest).<br /><br />
8.9 <b>Deductions from refunds if you are exercising your right to change your mind</b>. If you are exercising your right to change your mind:<br /><br />
          <div>
            (a) We may reduce your refund of the price (excluding delivery costs) to reflect any reduction in the value of the goods, if this has been caused by your handling them in a way which would not be permitted in a shop. &nbsp;If we refund you the price paid before we are able to inspect the goods and later discover you have handled them in an unacceptable way, you must pay us an appropriate amount.<br /><br />
  (b) The maximum refund for delivery costs will be the costs of delivery by the least expensive delivery method we offer. For example, if we offer delivery of a product within 3-5 days at one cost but you choose to have the product delivered within 24 hours at a higher cost, then we will only refund what you would have paid for the cheaper delivery option.<br /><br />
          </div>
8.10 <b>Ending the contract where we are not at fault and there is no right to change your mind:</b> Even if we are not at fault and you do not have a right to change your mind, you can still end the contract before it is completed, but you may have to pay us compensation. The contract for the Products is completed when the Product is delivered and paid for. If you want to end a contract before it is completed where we are not at fault and you do not have the right to change your mind, just contact us to let us know. The contract will end immediately and we will refund any sums paid by you for Products not provided but we may deduct from that refund (or, if you have not made an advance payment, charge you) reasonable compensation for the net costs we will incur as a result of your ending the contract.<br /><br />
        </div><br />
        <b>9. Complaints &amp; Returning Faulty Products</b>
        <br />
        <div>
          9.1 <b>How to tell us about problems:</b> Please contact us via email or the address provided in these Terms if you have any complaints about the Product. We aim to resolve any complaints as far as possible within 14 days.<br /><br />
9.2 <b>Returning defective Products:</b> In the event that the Product delivered to you is damaged or defective, please contact us as soon as possible using the contact details contained in clause 2.3 above. We will provide you with an address to return the Product with its original packaging back to us free of charge.<br /><br />
9.3 <b>Inspection of Product:</b> Upon our receipt of the returned Product, we reserve the right to inspect the Product to determine whether, in our opinion, it is faulty or not.<br /><br />
9.4 <b>How we will refund you if the Product is faulty:</b> If we agree that the Product is defective, we will offer you a full refund of the price of the Product (including any additional delivery charges paid). Alternatively, you may, where possible, choose to have the Product repaired and resent to your nominated delivery address free of charge. Given the nature of the Products, it may not always be possible to repair the Product. In this situation we will provide a full refund or a replacement Product to you.<br /><br />
9.5 <b>When you will receive your refund:</b> Once we confirm that you are entitled to a refund, we will aim to process your refund as soon as possible and, at the latest within 14 days of confirming that you are entitled to a refund. You will receive your refund to the credit or debit card that you used to pay.<br /><br />
9.6 <b>Your key legal rights:</b> We are under a duty to supply the Products in conformity with this Contract. Nothing in these terms will affect your legal rights. For detailed information regarding your legal rights please visit the Citizens Advice website <a href="www.adviceguide.org.uk" target="_blank" rel="noreferrer">www.adviceguide.org.uk</a> or call 03454 04 05 06.<br /><br />
9.7 <b>Our goodwill guarantee:</b> We also offer a Satisfaction Guarantee on the Products. Details of our Satisfaction Guarantee can be found <a href="https://www.vuse.com/gb/en/satisfaction" target="_blank" rel="noreferrer">here</a><br /><br />
        </div>
        <b>10. CREATING AN Account</b>
        <br />
        <div>
          10.1 <b>How to create an account:</b> You may place an order to purchase the Product either by creating an account on our Online Services or as a guest. In order to apply to create an account, please follow the instructions on our Online Services. It will be at our sole discretion as to whether we accept your application to create an account.<br /><br />
10.2 <b>We only sell to 18+ in the UK:</b> You must be aged 18 years or over with a UK registered postal address to create an account on our Online Services.<br /><br />
10.3 <b>One account per user:</b> Only one account may be created per individual user.<br /><br />
10.4 <b>What information do we require:</b> You will be asked to provide information including your name, email address and postal address when creating your account. Your email address will be used to identify you when you use it to access your account on our Online Services. We reserve the right to terminate your account in the event that you provide an invalid email address. Your order will be sent to the postal address you provide. We accept no responsibility for orders that are not received as a result of an incomplete or incorrect address being provided. You confirm that such information is true, accurate and complete and that you will notify us immediately if any part of this information changes.<br /><br />
10.5 <b>Choosing a password:</b> You will also need to provide a password in order to access your account. You are entirely responsible for maintaining the confidentiality of your password and you will be responsible for any damage or losses caused by unauthorised access resulting from your failure to keep your password secure. We encourage you to use a "strong" password (including a combination of numbers and letters). You agree to notify us immediately in the event of any unauthorised use, or suspected unauthorised use of your password or account.<br /><br />
        </div>
        <b>11. Cancelling Your Account</b>
        <br />
        <div>
          11.1 <b>We may cancel your account:</b> We may temporarily suspend or cancel your account at any time and for any reason without notice.<br /><br />
11.2 <b>How can you cancel your account:</b> You may cancel your account at any time and for any reason by contacting us by email or by post at the address set out in clause 2.3 above. It will be your responsibility to provide any required proof that you are the account holder.<br /><br />
        </div>
        <b>12. How We May Use Your Personal Information</b>
        <br />
        <div>
          12.1 <b>Use of your information:</b> Any personal data that you provide to us through our Online Services will be used in accordance with these Terms, &nbsp;our App Privacy Policy and our Website <a href="https://www.vuse.com/gb/en/privacy-notice" target="_blank" rel="noreferrer">Privacy Policy</a>. Please ensure that you have read our Privacy Policies before proceeding.<br /><br />
12.2 <b>Your consent to our use of your data:</b> By providing your personal data to us you are consenting to its use in accordance with these Terms and our <u>Privacy Policies (linked at paragraph 12.1 above)</u>.<br /><br />
12.3 <b>Your consent to our use of cookies:</b> Like many online services we use a feature called a 'cookie', which is a small data file that is sent to your browser from a web server and stored on your device's hard drive. &nbsp;References in these Terms to &lsquo;cookies&rsquo; also include other means of automatically accessing or storing information on your device. &nbsp;By agreeing to these Terms, you are providing your consent for us to use cookies in the ways described in our App <u>Cookie Policy</u> (as applicable) and our Website Cookie Policy, however, you may delete any of these cookies at any time if you wish. &nbsp;Please see our Cookie Policies for detailed information on the types of cookies we use on our Online Services, the purposes for which we use each cookie, how you can disable and enable the use of certain cookies and the consequences of doing so.<br /><br />
        </div>
        <b>13. Our responsibility for loss or damage suffered by you</b>
        <br />
        <div>
          13.1 <b>We are responsible to you for foreseeable loss and damage caused by us:</b> If we fail to comply with these terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking this contract or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time the contract was made, both we and you knew it might happen, for example, if you discussed it with us during the sales process.<br /><br />
13.2 <b>We do not exclude or limit in any way our liability to you where it would be unlawful to do so:</b> This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors; for fraud or fraudulent misrepresentation; for breach of your legal rights in relation to the products including the right to receive Products which are: as described and match information we provided to you and any sample or model seen or examined by you; of satisfactory quality; fit for any particular purpose made known to us; supplied with reasonable skill and care and, where installed by us, correctly installed; and for defective Products under the Consumer Protection Act 1987.<br /><br />
13.3 <b>We are not liable for business losses:</b> We only supply the Products for domestic and private use. If you use the Products for any commercial, business or re-sale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.<br /><br />
        </div>
        <b>14. Other important terms</b>
        <br />
        <div>
          14.1 <b>We may transfer this agreement to someone else:</b> We may transfer our rights and obligations under these terms to another organisation. &nbsp; We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.&nbsp;<br /><br />
14.2 <b>You need our consent to transfer your rights to someone else:</b> You may only transfer your rights or your obligations under these terms to another person if we agree to this in writing.<br /><br />
14.3 <b>Nobody else has any rights under this contract:</b> This contract is between you and us. No other person shall have any rights to enforce any of its terms. Neither of us will need to get the agreement of any other person in order to end the contract or make any changes to these terms.<br /><br />
14.4 <b>If a court finds part of this contract illegal, the rest will continue in force:</b> Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.<br /><br />
14.5 <b>Even if we delay in enforcing this contract, we can still enforce it later:</b> If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date. For example, if you miss a payment and we do not chase you but we continue to provide the products, we can still require you to make the payment at a later date.<br /><br />
14.6 <b>We may change, modify or revise these Terms at any time:</b> Any changes made to these Terms will apply 7 days after the date we post the modified terms on our Online Services. &nbsp;These changes will only apply to orders placed on or after the date on which the changes to the Terms take effect. &nbsp;It is your responsibility to check that you have read and agree with the latest Terms on our Online Services.<br /><br />
14.7 <b>Which laws apply to this contract and where you may bring legal proceedings:</b> These terms are governed by English law and you can bring legal proceedings in respect of the products in the English courts. If you live in Scotland you can bring legal proceedings in respect of the products in either the Scottish or the English courts. If you live in Northern Ireland you can bring legal proceedings in respect of the products in either the Northern Irish or the English courts.<br /><br />
        </div>
&nbsp;</p>
    </div>
  )
}

export default ConditionsOfSale;
