import React from 'react';
import './TermsAndConditions.scss';

import './../../policies.scss';

import vuseLogo from "./../../../images/vuseLogo.svg";
import LegalFooter from '../../../components/LegalFooter/LegalFooter';

const TermsAndConditions = () => (
  <>
    <div className="TermsAndConditions">
      {getHTML()}
    </div>
    <LegalFooter></LegalFooter>
  </>
);

function getHTML() {
  return <div id="policy">
    <a href="/">
      <img src={vuseLogo} alt=""></img>
    </a>
    <h1>MYVUSE TERMS AND CONDITIONS</h1><br />

    <b>
      1. Introduction<br /><br />
    </b>
    <div>
      1.1 <b>Please make sure you have read these App Terms and Conditions carefully and our Website Terms and Conditions, especially the limitations of our liability, before using the App.<br /></b>

1.2 These App Terms and Conditions ("<b>Terms</b>"), together with all information and documents referred to in them, govern your use of this mobile application (the &ldquo;<b>App</b>&rdquo;). Use includes accessing, browsing, or registering to use our App. Through the App you will have access to our website located at www.vuse.com/gb/en (the "<b>Website</b>"). It is therefore important that you make sure you have read our Website Terms and Conditions, especially the limitations of our liability, before using the App as these will apply to any use of the Website from through the App.<br />

1.3 By using this App, you agree to and accept these Terms and the Website Terms and Conditions. You should print a copy of both or save them for future reference.<br />

1.4 We may amend these Terms from time to time. Please review them regularly to ensure you understand the terms that apply each time you use the App. These Terms were most recently updated on 23rd March 2021.<br />

1.5 If you purchase any products ("<b>Products</b>") via the App, your purchase will also be governed by our App Conditions of Sale.&nbsp;<br />

1.6 If you do not agree to the Terms, you must not use the App. &nbsp;&nbsp;<br /><br />
    </div>
    <b>
      2. Information About Us<br /><br />
    </b>
    <div>
      2.1 The App is operated by British American Tobacco UK Limited t/a Vuse, a limited company registered in England and Wales under company number 02346565 with its registered office at Building 7, Chiswick Business Park, 566 Chiswick High Road, London, W4 5YG. ("Vuse", "we", "our" or "us"). ("<b>NT</b>", "<b>we</b>", "<b>our</b>" or "<b>us</b>").<br />

2.2 Our VAT registration number is 239136950.<br />

2.3 To contact us, please see our Contact Us page or email us at <a href="mailto:info.uk@vuse.com">info.uk@vuse.com</a> or write to us via post at Customer Services, British American Tobacco UK Limited, Building 7, Chiswick Business Park, 566 Chiswick High Road, London, W4 5YG.<br /><br />
    </div>
    <b>
      3. Accessing Our App&nbsp;<br /><br />
    </b>
    <div>
      3.1 You agree that you will only use the App &nbsp;for your own personal, non-commercial use.<br />

3.2 We do not guarantee that our App, or any content on it, will always be available or be uninterrupted. Access to our App is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of our App without notice. We will not be liable to you if for any reason the App is unavailable at any time or for any period.<br />

3.3 You are responsible for ensuring that you have all hardware and software necessary in order to access the App. You should use your own virus protection software when accessing the App. You are also responsible for ensuring that all persons who access our App through your internet connection are aware of these Terms and other applicable terms and conditions, and that they comply with them.<br />

3.4 You agree that you will not:<br />

a) use the App to in any way infringe the privacy or other rights of other users of the App or any third party;<br />

b) use the App in any way that may lead to the encouragement, procurement or carrying out of any criminal or unlawful activity, or cause distress, harm or inconvenience to any other person;<br />

c) do anything that may cause damage to the App or our servers, systems or equipment or those of third parties, nor access or attempt to access any users' data. &nbsp;You must not attack our App via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our App will cease immediately;<br />

d) do anything that subjects the App or Vuse to any derogatory treatment or brings (or might bring) the App or Vuse into disrepute;<br />

e) data mine, screen or web scrape or crawl this App, its pages or its content or use any process or processes that send automated queries to our App unless you have obtained our prior written consent;<br />

f) misuse or do anything that disrupts all or any part of the App, including but not limited to knowingly introducing software viruses, trojans, worms or other material that may be technologically harmful; or<br />

g) state, suggest or in any way give the impression that you have any commercial relationship with us, or that you are our agent or that we have endorsed any submission you may make to the App.<br />

3.5 We do not guarantee that the App will be secure or free from bugs or viruses.<br /><br />
    </div>
    <b>
      4. OUR CONTENT<br /><br />
    </b>
    <div>
      4.1 All copyrights and other intellectual property rights in the artwork, graphics, text, video and audio clips, trade marks, logos and other content available on this App (&ldquo;<b>Content</b>&rdquo;) are owned by us or used with permission.<br />

4.2 Although you can copy any part of this App to your own computer for your personal use, you may not copy or incorporate any of the Content available on the App into any other work, including your own App, or use the Content in any other public or commercial manner. Our status (and that of any identified contributors) as authors of the Content must always be acknowledged. You may not post or redistribute any portion of our App unless you have a license from us to do so. We retain full and complete title to all such Content, including any downloadable software or code, any images incorporated in or generated by the software, and all data accompanying it. You must not copy, modify, reproduce, upload, transmit, distribute, reverse-engineer, disassemble or otherwise convert it to another form.<br />

4.3 You may not change any of the notices regarding copyright, trade marks or other marks that may accompany the Content. You may link to the home page of our App, but may not use our Content in your own site. You may not deep link (i.e. link to any page other than the home page) to our App or frame our App on other applications or websites without our specific written permission.<br />

4.4 Unless otherwise specified, all trademarks used on our App are the property of Vuse or our group companies.<br />

4.5 If you print-off, copy or download the Content in breach of these Terms, your right to use the App will cease immediately and you must, at our option, return or destroy any copies of the Content you have made.<br /><br />
    </div>
    <b>
      5. YOUR PRIVACY AND OUR USE OF COOKIES<br /><br />
    </b>
    <div>
      5.1 Any personal data that you provide to us through our App will be used in accordance with these Terms, our Website Terms and Conditions, our App Privacy Policy and our Website <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>. Please ensure that you have read our Privacy Policies before proceeding.<br />

5.2 By providing your personal data to us you are consenting to its use in accordance with these Terms and our Privacy Policies. &nbsp;<br />

5.3 Like many online services we use a feature called a 'cookie', which is a small data file that is sent to your browser from a web server and stored on your device's hard drive. &nbsp;References in these Terms to &lsquo;cookies&rsquo; also include other means of automatically accessing or storing information on your device. &nbsp;By agreeing to these Terms, you are providing your consent for us to use cookies in the ways described in our App Cookie Policy (as applicable) and our Website <a href="/cookie-policy" target="_blank" rel="noreferrer">Cookie Policy</a>y, however, you may delete any of these cookies at any time if you wish. &nbsp;Please see our Cookie Policies for detailed information on the types of cookies we use on our App, the purposes for which we use each cookie, how you can disable and enable the use of certain cookies and the consequences of doing so.<br /><br />
    </div>
    <b>
      6. Liability<br /><br />
    </b>
    <div>
      6.1 We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:<br />

6.1.1 use of, or inability to use, our App; or<br />

6.1.2 use of or reliance on any content displayed on our App.<br />

6.2 If you are a business user, please note that in particular, we will not be liable for:<br />

6.2.1 loss of profits, sales, business, or revenue;<br />

6.2.2 business interruption;<br />

6.2.3 loss of anticipated savings;<br />

6.2.4 loss of business opportunity, goodwill or reputation; or<br />

6.2.5 any indirect or consequential loss or damage.<br />

6.3 Please note that we only provide our App for domestic and private use. You agree not to use our App for any commercial or business purposes.<br />

6.4 Although we make reasonable efforts to update the information on our App, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up-to-date.<br />

6.5 We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our App or to your downloading of any content on it, or on any application or website &nbsp;linked to it.<br />

6.6 Nothing in these Terms limits or excludes our liability for fraudulent misrepresentation, for death or personal injury resulting from our negligence or the negligence of our agents or employees or for any other liability that cannot be limited or excluded by law.<br />

6.7 This App may refer to products or services of third parties or link to third party sites or information. We do not endorse or make any warranties or representations about those products or services and accept no responsibility for the content of applications or websites linked on our App. Any links to other applications or websites are provided for your convenience only. We will not be liable for any loss or damage that may arise from your use of them. You need to make your own decision as to whether to use third party products, services, applications and websites.<br />

6.8 The content on our App is provided for general information only. It is not intended to amount to advice on which you should rely. Although we make reasonable efforts to update the information on our App, we make no representations, warranties or guarantees, whether express or implied, that the content on our App is accurate, complete or up-to-date.<br />

6.9 Different limitations and exclusions of liability will apply to liability arising as a result of the supply of any goods by use to you, which will be set out in our App Conditions of Sale. &nbsp;<br /><br />
    </div>
    <b>
      7. Events or circumstances beyond our control<br /><br />
    </b>
    <div>
      We will not be liable in any way for loss, damage or expense that you incur arising directly or indirectly from any failure or delay in performing any of our obligations under these Terms caused by any circumstances beyond our control, which include but are not limited to vandalism, accident, break down or damage to machinery or equipment, fire, flood, acts of God, strike, lock-out or other industrial disputes (whether or not involving our employees) or shortage of materials or fuel at the market rates existing when the order is accepted, legislative or administrative interference.<br /><br />
    </div>
    <b>
      8. OTHER IMPORTANT TERMS<br /><br />
    </b>
    <div>
      8.1 <b>Language.</b> &nbsp;These Terms may be presented to you in more than one language. However, the English language version of these Terms shall prevail.&nbsp;<br />

8.2 <b>Our reliance on these Terms.</b> &nbsp;We intend to rely on these written Terms and any document expressly referred to in them in relation to their subject matter. &nbsp;We and you will be legally bound by these Terms.<br />

8.3 <b>If a court finds part of these Terms illegal, the rest will continue in force.</b> &nbsp;Each of the clauses of these Terms operates separately. &nbsp;If a court or relevant authority decides that any of these Terms are unlawful, invalid or unenforceable, this will not affect the validity of the remaining Terms which will remain in full force and effect.<br />

8.4 <b>Even if we delay enforcing these Terms, we can still enforce them later.</b> If we fail to insist that you perform any of your obligations under these Terms, or if we delay or do not enforce our rights against you, this does not mean that we have waived our rights against you and does not mean that you do not have to perform your obligations.<br />

8.5 <b>You need our consent to transfer your rights to someone else.</b> You may only transfer your rights or your obligations under these Terms to another person if we agree to this in writing.<br />

8.6 <b>We may transfer our agreement under these Terms to someone else.</b> &nbsp;We may transfer our rights and obligations under these Terms to any company within our group, without any further consent from you.<br />

8.7 <b>Nobody else has any rights under these Terms.</b> &nbsp;These Terms are between you and us. Except in relation to any company within our group, no other person may enforce any of these Terms.<br />

8.8 <b>Written communications.</b> &nbsp;We will send notices and other communications to you at the e-mail address you have provided to us. You must send all notices and other communications to us using one of the communication methods referred to in clause 2.3. Any notices sent by e-mail or via the Contact Us form will be deemed to have been received 24 hours after the time sent by the sender. Any notices sent by first class post will be deemed to have been received on the next working day. Any notices issued by us that appear on our App will be deemed to have been received when you next use the App, unless expressly stated otherwise.<br />

8.9 <b>We may change these Terms.</b> &nbsp;We may change, modify or revise these Terms at any time. Any changes made to the Terms will apply 7 days after the date we post the modified terms on our App. It is your responsibility to check that you have read and agree with the latest Terms on the App.<br />

8.10 <b>Which laws apply to these Terms.</b> These Terms are governed by English law. You and we both agree that the courts of England and Wales will have non-exclusive jurisdiction. However, if you are a consumer and a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are a consumer and a resident of Scotland, you may also bring proceedings in Scotland.
</div>
  </div>
}

TermsAndConditions.propTypes = {};

TermsAndConditions.defaultProps = {};

export default TermsAndConditions;
