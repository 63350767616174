import React from 'react';
import './FeatureBloc.scss';

const FeatureBloc = (props) => (
  <div className={"FeatureBloc " + getAdditionnalClass(props)}>
    <div className="feature-icon">
      <img src={props.icon} alt="" />
    </div>
    <div className="feature-title">{props.title}</div>
    <p className="feature-description">{props.description}</p>
  </div>
);

FeatureBloc.propTypes = {};

FeatureBloc.defaultProps = {};

function getAdditionnalClass(props) {
  return (props.hiddenOnMobile !== undefined) ? "hidden-on-mobile" : "";
}

export default FeatureBloc;
