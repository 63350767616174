import React from 'react';
import './Favourites.scss';

import './../../content-page.scss';

import vuseLogo from "./../../../images/vuseLogo.svg";
import LegalFooter from '../../../components/LegalFooter/LegalFooter';

const Favourites = () => (
  <>
    <div id="content-page">
      <a href="/">
        <img src={vuseLogo} alt=""></img>
      </a>
      <h1>Favourites</h1><br />
      <div>
        When you create a Favourites list, we will collect information on your pod flavour preferences.<br /><br />

        <li>Flavour For You</li>
        <div
        >Where you enable the Flavour For You functionality, we collect information on your Favourites list, flavours previously ordered and flavours that you have asked us not to suggest. We will also send you regular communications (no more than once every two weeks) with suggestions of new flavours that we think you may enjoy. By enabling this functionality, you consent to us sending these communications. You can opt out of receiving these communications at any time by disabling the Flavour For You functionality.</div
        >
        <li>Order Reminder</li>
        <div>This data will be collected from you in relation to the threshold of personal stock set by you and the basket of pods that you may wish to purchase through your e-Commerce Account once that threshold is met, including Favourites</div>

      </div >
    </div>
    <LegalFooter></LegalFooter>
  </>
);

Favourites.propTypes = {};

Favourites.defaultProps = {};

export default Favourites;
