import React from 'react';

import playStoreIcon1 from './../../images/desktop/google-play-btn.png';
import playStoreIcon2 from './../../images/desktop/google-play-btn@2x.png';

import myVuseIcon1 from './../../images/desktop/myvuse-btn.png';
import myVuseIcon2 from './../../images/desktop/myvuse-btn@2x.png';

import './AndroidBlock.sass';

const AndroidBlock = () => (

    <div className="install-on-top-down android-block">
        <div className="install-on-title">INSTALL ON ANDROID</div>
        <p className="install-on-description">
            On an android device, click on the Google Play link,<br /> download and install the MYVUSE app,<br />launch it and follow the instructions on screen.
        </p>
        <div className="play-store-image left-item">
            <a href="https://play.google.com/store/apps/details?id=com.bat.uk.myvuse" target="_blank">
                <img alt="Google Play logo" src={playStoreIcon1} srcSet={`${playStoreIcon1} 1x, ${playStoreIcon2} 2x`} />
            </a>
        </div>
        <div className="vertical-line"></div>
        <div className="my-vuse-icon install-on-linked-item">
            <img alt="My Vuse logo" src={myVuseIcon1} srcSet={`${myVuseIcon1} 1x, ${myVuseIcon2} 2x`} />
        </div>
    </div>

);

export default AndroidBlock