import React from 'react';

import FeatureBloc from '../../components/FeatureBloc/FeatureBloc';

import playStoreIcon1 from './../../images/desktop/google-play-btn.png';
import playStoreIcon2 from './../../images/desktop/google-play-btn@2x.png';

import bluefyIcon from './../../images/desktop/blufy_logo@2x.png';

import myVuseApp2 from './../../images/desktop/logo-myvuse@2x.png';
import myVuseApp1 from './../../images/desktop/logo-myvuse.png';

import myVuseAppLogoMobile1 from './../../images/mobile/myviews_logo.png';
import myVuseAppLogoMobile2 from './../../images/mobile/myviews_logo@2x.png';

import './header.sass';

import findMyVapeIcon from './../../images/desktop/find_my-vape.svg';
import cloudControlIcon from './../../images/desktop/cloud-control.svg';
import usageTrackingIcon from './../../images/desktop/usage-tracker.svg';
import easyReorderIcon from './../../images/desktop/easy-reorder.svg';
import rechargeReminderIcon from './../../images/desktop/recharge-reminder.svg';
import podTrackerIcon from './../../images/desktop/tracker.svg';
import favouritesIcon from './../../images/desktop/faves.svg';
import lockDeviceIcon from './../../images/desktop/device-lock.svg';

// import download_btn2 from './../../images/mobile/download_btn@2x.png';
// import download_btn1 from './../../images/mobile/download_btn.svg';

const Header = () => (

    <div className="foreground-content">

        <div className="text-image-flex">
            <p className="page-title">
                YOUR NEW VAPING EXPERIENCE<br />STARTS WITH THE
                <img className="bluefy-icon" alt=""
                    src={myVuseApp1}
                    srcSet={`${myVuseApp1} 1x, ${myVuseApp2} 2x`}
                /><span>APP</span>
                <img className="my-vuse-app-logo-mobile" src={myVuseAppLogoMobile1} srcSet={`${myVuseAppLogoMobile1} 1x, ${myVuseAppLogoMobile2} 2x`} alt="" />
            </p>
        </div>

        <div id="download-app-container">
            <div className="play-store-image left-item">
                <a href="https://play.google.com/store/apps/details?id=com.bat.uk.myvuse" target="_blank">
                    <img src={playStoreIcon1} srcSet={`${playStoreIcon1} 1x, ${playStoreIcon2} 2x`} alt="" />
                </a>
            </div>
            <div className="top-down right-item">
                
                <a href="https://apps.apple.com/gb/app/bluefy-web-ble-browser/id1492822055" target="_blank">
                    <div className="bluefy-rectangle">
                        <span>DOWNLOAD</span>
                        <span className="bluefy-rectangle-text">BLUEFY BROWSER TO USE APP</span>
                    </div>
                </a>

                <div className="side-by-side-close">
                    <div className="bluefy-image">
                        <a href="https://apps.apple.com/gb/app/bluefy-web-ble-browser/id1492822055" target="_blank">
                            <img src={bluefyIcon} alt="" />
                        </a>
                    </div>
                    <p className="download-bluefy-paragraphe">
                        If you are an iOS user, Bluefy browser will allow you to access the app on your phone. Once the browser is downloaded, enter <a href="https://www.uk-my.vuse.com" target="_blank"><b>uk-my.vuse.com</b></a>in the URL to access the app.
                    </p>
                </div>
            </div>
        </div>

        <div className="downloads">
            <div>
                <a href="https://play.google.com/store/apps/details?id=com.bat.uk.myvuse" target="_blank">
                    <img src={playStoreIcon1} srcSet={`${playStoreIcon1} 1x, ${playStoreIcon2} 2x`} alt="" />
                </a>
            </div>
            <div>
                <a href="https://apps.apple.com/gb/app/bluefy-web-ble-browser/id1492822055" target="_blank">
                    <div className="bluefy-rectangle">
                        <span>DOWNLOAD</span>
                        <span className="bluefy-rectangle-text">BLUEFY BROWSER TO USE APP</span>
                    </div>
                </a>
                <div className="container">
                    <a href="https://apps.apple.com/gb/app/bluefy-web-ble-browser/id1492822055" target="_blank">
                        <img className="bluefy-icon" src={bluefyIcon} alt="" />
                    </a>
                </div>
                <p className="download-bluefy-paragraphe">
                    If you are an iOS user, Bluefy browser will allow you to access the app on your phone. Once the browser is downloaded, enter <b>uk-my.vuse.com</b>in the URL to access the app.
                </p>
            </div>
        </div>

        <p className="paragraphe-text">
            Our new MYVUSE APP allows you to easily connect and control your ePOD+ via your phone. With it, you can make the most of your vaping experience by using features like find my vape, cloud control, usage tracker and easy reorder. Download the App to unlock these features and more.
        </p>

        <p className="small-bold-text">SCROLL DOWN TO EXPLORE<br />KEY FEATURES</p>


        <div className="features-grid">
            <FeatureBloc
                title={'FIND MY VAPE'}
                description={"Can’t find your device? Don’t worry, we’ll show you it’s last synced location."}
                icon={findMyVapeIcon}
            ></FeatureBloc>
            <FeatureBloc
                title={'CLOUD CONTROL'}
                description={'Use the app to control your vapour cloud size..'}
                icon={cloudControlIcon}
            ></FeatureBloc>
            <FeatureBloc
                title={'USAGE TRACKING'}
                description={'Track your device usage with puff and pod count.'}
                icon={usageTrackingIcon}
            ></FeatureBloc>
            <FeatureBloc
                title={'EASY REORDER'}
                description={'All your favourites will be waiting for you in your basket, ready for you to reorder.'}
                icon={easyReorderIcon}
            ></FeatureBloc>
            <FeatureBloc
                title={'RECHARGE REMINDER'}
                description={'Make sure your device is always charged by setting alerts for your battery levels.'}
                icon={rechargeReminderIcon}
                hiddenOnMobile={true}
            ></FeatureBloc>
            <FeatureBloc
                title={'POD TRACKER'}
                description={"We can notify you when you are running out of pods by setting a threshold."}
                icon={podTrackerIcon}
                hiddenOnMobile={true}
            ></FeatureBloc>
            <FeatureBloc
                title={'FAVOURITES'}
                description={"Speed up your order, once they're in your favourites, your order is just one click away."}
                icon={favouritesIcon}
                hiddenOnMobile={true}
            ></FeatureBloc>
            <FeatureBloc
                title={'LOCK DEVICE'}
                description={'Lock your device so no one else but you has access.'}
                icon={lockDeviceIcon}
                hiddenOnMobile={true}
            ></FeatureBloc>
        </div>

    </div>

);

export default Header