import React from 'react';
import './LegalFooter.scss';

import myVuseLogo from './../../images/Legal-doc-headline/Legal-doc-headline@2x.png';

const LegalFooter = () => (
  <div className="footer">

    <div className="pages-section">
      <hr></hr>
      <div className="my-vuse-legal-logo">

        <img src={myVuseLogo} alt=""></img>
      </div>
      <div className="policies-list">
        <a href="/privacy-policy" target="_blank" rel="noreferrer" className="policy-btn">
          PRIVACY POLICY
        </a>
        <a href="/terms-and-conditions" target="_blank" rel="noreferrer" className="policy-btn">
          TERMS & CONDITIONS
        </a>
        <a href="/conditions-of-sale" target="_blank" rel="noreferrer" className="policy-btn">
          CONDITIONS OF SALE
        </a>
        <a href="/subscription-terms-and-conditions" target="_blank" rel="noreferrer" className="policy-btn">
          SUBSCRIPTION TERMS & CONDITIONS
        </a>
        <a href="/cookie-policy" target="_blank" rel="noreferrer" className="policy-btn">
          COOKIE POLICY
        </a>
      </div>
      <hr id="notice-hr"></hr>
    </div>
    <div>

      <p className="disclaimer-paragraphe">
        Vuse electronic cigarettes may be hazardous to health and contain nicotine which is addictive. Vuse electronic cigarettes are not suitable for use by: persons under the age of 18; persons who are allergic/sensitive to nicotine; pregnant or breast-feeding women; persons who should avoid using tobacco or nicotine products for medical reasons; or persons with an unstable heart condition, severe hypertension or diabetes. Keep Vuse products out of reach of children.
      </p>
      <p className="disclaimer-paragraphe">
        ©British American Tobacco. Building 7, Chiswick Business Park, 566 Chiswick High Road, London, W4 5YG. Registered in England & Wales: 02346565. VAT Registered: 239136950. Vuse is distributed by BAT UK Limited.
      </p>
    </div>
  </div>
);

LegalFooter.propTypes = {};

LegalFooter.defaultProps = {};

export default LegalFooter;
