import React, { useState } from 'react';

import './PhoneCarousel.scss';
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import phone1 from './../../images/desktop/find-phone.png';
import phone11 from './../../images/desktop/find-phone@2x.png';

import phone2 from './../../images/desktop/sub-phone.png';
import phone21 from './../../images/desktop/sub-phone@2x.png';

import phone3 from './../../images/desktop/tracker-phone.png';
import phone31 from './../../images/desktop/tracker-phone@2x.png';

import phone4 from './../../images/desktop/cloud-phone.png';
import phone41 from './../../images/desktop/cloud-phone@2x.png';

import phone5 from './../../images/desktop/LOCK-DEVICE-phone.png';
import phone51 from './../../images/desktop/LOCK-DEVICE-phone@2x.png';

import phone6 from './../../images/desktop/RECHARGE-phone.png';
import phone61 from './../../images/desktop/RECHARGE-phone@2x.png';

import { Slide } from "react-awesome-reveal";

const PhoneCarousel = () => {
  const [direction, setDirection] = useState('down');
  
  function update(inView,entry){
    if( entry.boundingClientRect.top < 0 ){
      setDirection('up');
    }else{
      setDirection('down');
    }
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: '50px',
    lazyLoad: false,
    centerMode: false,
    arrows: true,
    className: 'slides',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
          centerMode: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: false
        }
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: false,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <div className="PhoneCarousel">

      <Slide 
        direction={direction} 
        onVisibilityChange={ (inView,entry) => update(inView,entry) } > 

        <Slider {...settings}>
          <img src={phone4} srcSet={`${phone4} 1x, ${phone41} 2x`} alt="" />
          <img src={phone3} srcSet={`${phone3} 1x, ${phone31} 2x`} alt="" />
          <img src={phone1} srcSet={`${phone1} 1x, ${phone11} 2x`} alt="" />
          <img src={phone5} srcSet={`${phone6} 1x, ${phone61} 2x`} alt="" />
          <img src={phone2} srcSet={`${phone2} 1x, ${phone21} 2x`} alt="" />
          <img src={phone5} srcSet={`${phone5} 1x, ${phone51} 2x`} alt="" />
        </Slider>

      </Slide>

    </div>
  );

}

PhoneCarousel.propTypes = {};

PhoneCarousel.defaultProps = {};

export default PhoneCarousel;
