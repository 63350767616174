import React from 'react';

import bluefyIcon1 from './../../images/desktop/blufy_logo.png';
import bluefyIcon2 from './../../images/desktop/blufy_logo@2x.png';

import myVuseIcon1 from './../../images/desktop/myvuse-btn.png';
import myVuseIcon2 from './../../images/desktop/myvuse-btn@2x.png';

import './IOSBlock.sass';

const IOSBlock = () => (

    <div className="install-on-top-down ios-block ">
        <div className="install-on-title">INSTALL ON iOS</div>
        <p className="install-on-description">
            On iOS device, Download the Bluefy browser from the App Store, once installed, launch the browser and navigate to <a href="https://www.uk-my.vuse.com" target="_blank">uk-my.vuse.com</a> to access the app, follow the instructions on screen to set up your device.
        </p>
        <div className="install-on-linked-items-container">
            <a href="https://apps.apple.com/gb/app/bluefy-web-ble-browser/id1492822055" target="_blank">
                <div className="bluefy-rectangle">
                    <span>DOWNLOAD</span>
                    <span className="bluefy-rectangle-text">BLUEFY BROWSER TO USE APP</span>
                </div>
            </a>
            <div className="vertical-line vertical-line-1"></div>
            <div className="bluefy-image">
                <a href="https://apps.apple.com/gb/app/bluefy-web-ble-browser/id1492822055" target="_blank">
                    <img alt="Bluefy logo" src={bluefyIcon1} srcSet={`${bluefyIcon1} 1x, ${bluefyIcon2} 2x`} />
                </a>
            </div>
            <div className="vertical-line vertical-line-2"></div>
            <div id="my-vuse-com-bordered-container">UK-MY.VUSE.COM</div>
            <div className="vertical-line vertical-line-3"></div>
            <div className="my-vuse-icon install-on-linked-item">
                <img alt="My Vuse logo" src={myVuseIcon1} srcSet={`${myVuseIcon1} 1x, ${myVuseIcon2} 2x`} />
            </div>
        </div>
    </div>

);

export default IOSBlock