import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import React, { useState } from 'react';
import { CookiesProvider } from "react-cookie";
import { useCookies } from 'react-cookie';

import './App.css';
import './pages/Home/Home.scss';
import Home2 from './pages/Home/Home2.js';
import UsageTracker from './pages/content/UsageTracker/UsageTracker';
import SmartAssistant from './pages/content/SmartAssistant/SmartAssistant';
import Favourites from './pages/content/Favourites/Favourites';
import FindMyVape from './pages/content/FindMyVape/FindMyVape';

import ConditionsOfSale from './pages/policies/ConditionsOfSale/ConditionsOfSale';
import CookiePolicy from './pages/policies/CookiePolicy/CookiePolicy';
import PrivacyPolicy from './pages/policies/PrivacyPolicy/PrivacyPolicy';
import SubscriptionTermsAndConditions from './pages/policies/SubscriptionTermsAndConditions/SubscriptionTermsAndConditions';
import TermsAndConditions from './pages/policies/TermsAndConditions/TermsAndConditions';

import AgePopup from './components/AgePopup/AgePopup';

function App() {

  const [hidePopupCookie, setHidePopup] = useCookies(['hidePopup']);
  let [popupVal, setPopupVal] = useState(hidePopupCookie['hidePopup']);

  function tryhidePopup() {
    return (
      !popupVal ? <AgePopup accessWebsite={() => togglePopup(true)}></AgePopup> : <></>
    );
  }

  function togglePopup(toggle) {
    setHidePopup('hidePopup', toggle, { path: '/' })
    setPopupVal(toggle)
  }

  return (
    <>
      <CookiesProvider>
        <Router>
          <Switch>
            <Route exact path="/">
              {tryhidePopup()}
              <Home2 />
            </Route>
            <Route path="/usage-tracker">
              <UsageTracker />
            </Route>
            <Route path="/smart-assistant">
              <SmartAssistant />
            </Route>
            <Route path="/favourites">
              <Favourites />
            </Route>
            <Route path="/find-my-vape">
              <FindMyVape />
            </Route>
            {/* Policies */}
            <Route path="/conditions-of-sale">
              <ConditionsOfSale />
            </Route>
            <Route path="/cookie-policy">
              <CookiePolicy />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/subscription-terms-and-conditions">
              <SubscriptionTermsAndConditions />
            </Route>
            <Route path="/terms-and-conditions">
              <TermsAndConditions />
            </Route>
          </Switch>
        </Router>
      </CookiesProvider>
    </>
  );
}

export default App;