import React from 'react';
import './SubscriptionTermsAndConditions.scss';

import './../../policies.scss';

import vuseLogo from "./../../../images/vuseLogo.svg";
import LegalFooter from '../../../components/LegalFooter/LegalFooter';

const SubscriptionTermsAndConditions = () => (
  <>
    <div className="SubscriptionTermsAndConditions">
      {getHTML()}
    </div>
    <LegalFooter></LegalFooter>
  </>
);

function getHTML() {
  return (
    <div id="policy">
      <a href="/">
        <img src={vuseLogo} alt=""></img>
      </a>
      <h1>SUBSCRIPTION TERMS &amp; CONDITIONS</h1><br />
      <b>About Us</b><br />

      <p><li>Vuse is a trading name of British American Tobacco UK Limited whose registered office is at Building 7, Chiswick Business Park, 566 Chiswick High Road, London, W4 5YG ("<b>Vuse</b>", "<b>we</b>", "<b>our</b>", or "<b>us</b>" as applicable).<br /></li>
      </p>

      <li>The Vuse Subscription Terms &amp; Conditions ("<b>Terms</b>") are listed below. If you have any questions about our subscription services or products, please contact us at 0800 1337 350 / info.uk@vuse.com. The Vuse Care team operate within the hours of 9am to 5pm Monday to Friday excluding Bank Holidays.<br /></li>
      <li>We may amend these Terms from time to time. Unless we expressly provide a delayed effective date, all amendments will be immediately effective on the posting of such updated Terms. <b>Your continued use of our Vuse subscription services constitutes your consent to be bound by these Terms, as amended from time to time. Please therefore check this page occasionally to ensure that you&rsquo;re happy with any changes to these Terms</b>.<br /></li><br />
      <b>How to Create a Subscription</b><br />
      <li>To create a subscription, you must be an existing smoker or vaper, aged 18 years or over, with a UK registered postal address, have an account profile set up within Vuse.com (the "<b>Website</b>") or our mobile application (the "<b>App</b>") and provide a valid Payment Method (as described below) ("<b>Subscription</b>").<br /></li>
      <li>Each successful Subscription created by you will become immediately effective upon completion of the checkout process for that particular Subscription, until such time as the Subscription is cancelled or terminated (as described below) or modified or paused by you or by us in accordance with these Terms.<br /></li>
      <li>Once you have successfully created a Subscription you will receive an email from us to confirm that your Subscription is active.<br /></li >
      <li>We are not responsible or liable for any errors, omissions or faults caused by you during the creation of any Subscription and you warrant that any and all information provided by you is true and accurate. If you require further assistance on how to create a Subscription, please visit the Subscription page on this App or our Website or contact the Vuse Care team.<br /></li>
      <li>Once your Subscription reaches the end of its current term, as selected by you during the creation process, it will automatically renew on a rolling 1-month basis until cancelled, paused or modified by you (see below for further details).<br /></li><br />
      <b>Subscription Products</b><br />
      <li>Subscriptions are only available on selected Vuse devices and e-liquid cartridges and in the quantities as presented on this App or on the Website from time to time ("<b>Products</b>").<br /></li>
      <li>You may create multiple Subscriptions at any given time. If you wish to cancel, amend or pause an existing Subscription, you can do so on the App by opening the Subscriptions tab or on the Website logging in to your account profile and accessing your Subscriptions. Please contact the Vuse Care team for further assistance.<br /></li>
      <li>If you pause or amend the details of your Subscription, all such changes to your Subscription shall be governed by these Terms and these Terms shall continue to apply until your Subscription is either cancelled or terminated in accordance with these Terms.<br /></li>
      <li>If your Subscription includes additional or replacement devices, you will be required to redeem the additional or replacement device. You will receive an email from Vuse with instructions on how to redeem your device. The choice of additional or replacement devices offered by Vuse will be at Vuse&rsquo;s sole discretion and no cash alternative will be offered for any &nbsp;failure or decision by you to redeem your additional or replacement device.<br /></li>
      <li>You acknowledge that the Subscription price (as applicable from time to time) cannot be used in conjunction with any other price structures available on the Products (or any other offer generally available through Vuse) as published by us from time to time.<br /></li>
      <li>We will use commercially reasonable endeavours to ensure the availability of the Products on our App and our Website but cannot guarantee any Product availability. In the event any of the Products placed under a Subscription are out of stock (regardless of whether this is due to normal stock fluctuations or any event outside of our reasonable control), we will not be able to deliver the relevant aspects of the Subscription to you and will not be liable for any consequences suffered or incurred by you as a result. If this event occurs, our Vuse Care team will be in touch to let you know what alternative products are available in stock in order for you to continue your Subscription, for that specific month, or to discuss other options available to you.<br /></li>
      <b>Billing</b><br />
      <li>We only accept payment by Visa or MasterCard ("<b>Payment Method</b>" ). We reserve the right to update and/or otherwise amend your chosen Payment Method from time to time.<br /></li>
      <li>By registering for a Subscription, you authorise us to charge your Payment Method for your initial Subscription and each Subscription created thereafter in accordance with the applicable Subscription price(s) as published by us and as applicable from time to time. Please note that the Vuse payment facility is operated by Worldpay, who will process your chosen Payment Method on our behalf. Your use of the Vuse payment facility will be subject to their terms and conditions. <b>You should ensure that you have read and agree with the Worldpay terms and conditions before using the Vuse payment facility.</b><br /></li>
      <li>Worldpay operates a secure server to process your payment details. They encrypt your credit or debit card (or other relevant) information and authorise payment directly. We will only keep the last four digits of your credit or debit card for our records and in order for you to recognise and choose your desired Payment Method without having to type in the details each time, therefore creating a more streamlined shopping experience for you. This means that your full credit card or debit card details are never revealed to and/or stored by us. To understand how our payment processor uses your information, we recommend that you read their privacy policy: <a href="http://www.worldpay.com/uk/privacy-policy" target="_blank" rel="noreferrer">http://www.worldpay.com/uk/privacy-policy</a>.<br /></li>
      <li>When you create a Subscription, your first Subscription fee will be billed immediately. Unless we expressly communicate otherwise, any subsequent fees relating to your Subscription will be charged on the date the Products are dispatched to you until such time as your Subscription is cancelled by you or by us in accordance with these Terms.<br /></li>
      <li>The fees, if any, for each dispatched Subscription delivery will be billed to the Payment Method chosen by you to create the relevant Subscription or as otherwise directed by you from time to time. If we are unable to obtain payment from you for your Subscription order for any reason, we will notify you via e-mail asking you to resolve the problem. Your Subscription will be placed on hold and no additional orders will be processed or shipped until the problem has been resolved by you. If the payment issue is not resolved within a reasonable time from you being notified by us, we reserve the right to cancel your Subscription immediately without notice to you.<br /></li>
      <b>Delivery</b><br />
      <li>All Subscriptions are subject to standard delivery dispatch only and therefore exclude any &lsquo;next day&rsquo; or &lsquo;Saturday&rsquo; delivery options. Please see our <u>Delivery &amp; Returns</u> terms for further information.<br /></li>
      <li>Standard delivery will be provided for all Subscriptions without any additional fees being charged to you.<br /></li>
      <li><b>Please note that further delivery restrictions may apply to your Subscription(s)</b>. For further information on delivery restrictions (in particular, relating to certain geographical delivery restrictions) please see our <u>Delivery &amp; Returns</u> terms for further information.<br /></li>
      <b>Cancelling and Pausing Your Subscription</b><br />
      <li>There may be occasions when, despite our efforts, we are unable to dispatch the Products relating to your Subscription. This may include (but is not limited to) circumstances where any of the Products in your Subscription are out of stock (whether due to normal stock fluctuations or otherwise) or have otherwise been discontinued at the time your Subscription is due to be dispatched.<br /></li>

      <li>If any of the events under clause 24 arise, the following procedure will apply:<br /></li>
      <div>
        <li>You will receive an email from us to confirm that there has been an issue with your Subscription and that delivery of your Products will not take place.<br /></li>
        <li>The Vuse Care team will contact you directly to see if they can provide a remedy to the above Subscription issue.<br /></li>
        <li>In the event a remedy can be provided by the Vuse Care team, (for example, you confirm that you are happy to proceed with the change of the Product(s) in your Subscription, or if we can provide you with a one-off substitution for any out of stock Products) the Subscription will be removed from the &lsquo;paused&rsquo; state and the Subscription will resume.<br /></li>
        <li>In the event that we are unable to resolve the issue with your Subscription, (for example, one of the Products in your Subscription has been discontinued) we will discuss with you the options available which may include the &lsquo;paused&rsquo; Subscription being cancelled and a new Subscription for current available Products being set up.<br /></li>
      </div>
      <li>There may be occasions when you decide that you wish to &lsquo;pause&rsquo; your own Subscription. For example, you may wish to do this if you know that you will be away on holiday. If you would like to pause an existing live Subscription, please undertake the following steps:<br /></li>
      <div>
        <b>App:</b><br />
        <li>Open your App;&nbsp;<br /></li>
        <li>Click the &lsquo;Subscriptions&rsquo; tab; and<br /></li>
        <li>Click the &lsquo;Edit&rsquo; button and then the &lsquo;Pause Subscription&rsquo; button.&nbsp;<br /></li>
        <b>Website:</b><br />
        <li>Please login to your personal Vuse Account;<br /></li>
        <li>Click the &lsquo;Subscriptions&rsquo; tab; and<br /></li>
        <li>Click the &lsquo;Pause Subscription&rsquo; button.<br /></li>
      </div>
      <li>Please note that the &lsquo;pause&rsquo; function may only be used once per Subscription and all Subscriptions that have been placed in a &lsquo;paused&rsquo; state by you, in accordance with clause 26 above, will only remain paused for a period of 1 month. Following this period, your Subscription will then be automatically reactivated and the applicable end date of the term for that particular Subscription will be extended accordingly. If you require further assistance with pausing your Subscription or if you have any other related queries, then please contact the Vuse Care team.<br /></li>
      <b>Amending your Subscription</b><br />
      <li>You may change your Subscription at any time to increase or decrease the quantities of cartridges. Any such changes may result, depending on your selection, in your Subscription Plan being changed (for example, from Bronze to Gold or vice versa) which may increase or decrease your Subscription costs. You can make changes in the My Subscriptions section of your account.<br /></li>
      <b>Cancelling your Subscription<br /></b>
      <li>You are entitled to cancel your Subscription, by notifying us in writing or by calling our Vuse Care team, within 14 days of receiving your first Subscription delivery (the &lsquo;cooling-off&rsquo; period) provided you return any unused, unsealed Products to us. The opening of sealed items invalidates the right to cancel during this &lsquo;cooling-off&rsquo; period. Once you have notified of us your decision to cancel, you will have a further 14 days from this point in which to return your sealed items to us. If you need further assistance with this process, please contact the Vuse Care team.<br /></li>
      <li>After the &lsquo;cooling-off&rsquo; period of 14 days has passed, your Subscription can still be cancelled at any time on the App by opening the Subscriptions tab or on the Website by logging into your account and following the instructions contained in the My Subscriptions section of your account. Alternatively, you can notify us in writing or by phone.<br /></li>
      <li>If we receive your request to cancel a Subscription after dispatching any Product(s) to you, you must return the Product(s) to us in good, unused and sealed condition.<br /></li>
      <b>Return of Products</b><br />
      <li>If you need to return any Products to us for any reason, please see further information how to do this here: &lsquo;<u>Delivery and Returns</u>&rsquo; or call our Vuse Care team for assistance.<br /></li>
      <b>Data Protection</b><br />
      <li>Personal data that you provide to us by signing up to a Subscription will only be used in accordance with these Terms and our Website <a href="https://www.govype.com/gb/en/privacy-policy-cookie-restriction-mode/" target="_blank" rel="noreferrer">Privacy Notice</a>. Please ensure that you have read our Website <a href="https://www.govype.com/gb/en/privacy-policy-cookie-restriction-mode/" target="_blank" rel="noreferrer">Privacy Notice</a> before proceeding.<br /></li>
      <b>General</b><br />
      <li>We reserve the right to end any Subscription(s) and/or decline the creation of any Subscription(s):<br /></li>
      <div>
        <li>at any time;<br /></li>
        <li>for any reason; and<br /></li>
        <li>without notice to you.<br /></li>
      </div>
      <li>We reserve the right (in our absolute discretion) to take any action we believe to be appropriate if we consider that any Vuse account holders are abusing the Subscription services, which may include (but not limited to) terminating your existing Vuse registered account.<br /></li>
      <li>If any provision of these Terms is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force.<br /></li>
      <li>A failure by Vuse to exercise, or delay in exercising, a right, power or remedy provided by these Terms or by law shall not constitute a waiver of that, or any other, right, power or remedy and shall not, and nor shall any single or partial exercise of any such right, power or remedy, preclude the further exercise of that, or any other, right, power or remedy.<br /></li>
      <li>We do not accept any responsibility nor any liability (unless such liability cannot be lawfully excluded) for your use of the Subscription (including but not limited to use of your Subscription account) where such use is undertaken in a manner which is inconsistent with these Terms, the App Terms and Conditions and/or the Website Terms and Conditions. &nbsp;<br /></li>
      <li>We do not accept responsibility and will not be liable (unless such liability cannot be lawfully excluded) for any losses, damages, expenses or other liabilities arising from or in connection with (whether direct or indirect) any technical fault, technical malfunction, computer hardware or software failure, satellite, network or server failure of any kind or for any errors solely due to the actions by you relating to a Subscription or for any circumstances beyond our control, including but not limited to, accident, fire, flood, acts of God, strike, lock-out or other industrial disputes (whether or not involving our employees) or legislative or administrative interference.<br /></li>
      <li>These Terms will be governed by English law and you hereby agree to submit to the exclusive jurisdiction of the courts of England and Wales.</li>
    </div>
  )
}

SubscriptionTermsAndConditions.propTypes = {};

SubscriptionTermsAndConditions.defaultProps = {};

export default SubscriptionTermsAndConditions;
