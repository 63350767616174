import React from 'react';
import './FindMyVape.scss';

import './../../content-page.scss';

import vuseLogo from "./../../../images/vuseLogo.svg";
import LegalFooter from '../../../components/LegalFooter/LegalFooter';

const FindMyVape = () => (
  <>
    <div id="content-page">
      <a href="/">
        <img src={vuseLogo} alt=""></img>
      </a>
      <h1>Find My Vape</h1>
    Where you enable the Find My Vape functionality,
    data is collected to enable you to see the last location where your Device was synced with the App,
    including GPS location of the last synchronisation,
    and timestamp of the last synchronisation between the Device and App
  </div>
    <LegalFooter></LegalFooter>
  </>
);

FindMyVape.propTypes = {};

FindMyVape.defaultProps = {};

export default FindMyVape;
